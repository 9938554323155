import React, { useState } from "react";
import EsignModal from "../../components/eSignModal";

const DemoGuide = ({ isModalOpen, setIsModalOpen }) => {
  const [isNext, setIsNext] = useState(false);
  return (
    <EsignModal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className={`max-w-[${isNext ? "40rem" : "30rem"}] mx-auto p-6 bg-white rounded-lg shadow-md shadow-lg shadow-black relative`}
    >
      {isNext ? (
        <>
          {/* <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md"> */}
            {/* Document Image Placeholder */}
            <div className="mb-6 relative flex justify-center items-center">
            <img
              src="/demoGuide.png" // Replace with your image source or use a placeholder image
              alt="Document"
              className="w-full h-[10rem] rounded-lg"
            />
            </div>

            {/* Instruction text */}
            <div className="text-center mt-4 justify-center items-center flex flex-col">
              <p className="text-lg text-gray-700">
                Touchez pour ajouter l'un de ces types de champs
              </p>
              <img
                src="/demoTool.png" // Replace with your image source or use a placeholder image
                alt="Document"
                className="self-center w-[10rem]"
              />
            </div>
            <div className="flex justify-center items-center pt-[2rem]">
            <button
              onClick={() => setIsModalOpen(false)}
              className="w-[15rem]  py-2 bg-secondary text-white rounded-md font-semibold hover:bg-orange-600 transition duration-300"
            >
              Merci, j'ai compris !
            </button>
          </div>
        </>
      ) : (
        <>
          {/* <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-md"> */}
          {/* <div className="flex items-center mb-6">
        <span className="text-3xl font-semibold text-red-600">C</span>
        <span className="text-2xl font-semibold ml-1 text-gray-700">R</span>
      </div> */}
          <h2 className="text-lg font-bold text-gray-700 mb-4 text-center">
            Montrez-nous où vous voulez des signatures
          </h2>
          <p className="text-gray-500 mb-6 text-center">
            Vous pouvez ajouter des champs pour les signatures, les initiales,
            les dates, etc.
          </p>
          <button
            onClick={() => setIsNext(true)}
            className="w-full py-2 bg-secondary text-white rounded-md font-semibold hover:bg-orange-600 transition duration-300"
          >
            Ajouter des champs de signature
          </button>
          <div className="mt-4 text-center">
            <a href="#" className="text-blue-500 hover:underline">
              Poser une question juridique
            </a>
          </div>
        </>
      )}
      {/* <div className="mt-4 text-center">
        <a href="#" className="text-orange-500 hover:underline">
          Autres options
        </a>
      </div> 
    </div>*/}
    </EsignModal>
  );
};

export default DemoGuide;
