import React from "react";
import { Navigate } from "react-router-dom";
import { getCookie } from "../_helpers/cookie";

function Protected({ children }) {
  const getUser = JSON.parse(localStorage.getItem('user_pro'));
  const authToken = getCookie("authToken");
  const isLoggedIn = getUser && authToken && (getUser.success || getUser.sucess);

  return isLoggedIn ? children : <Navigate to="/" replace />;
}

export default Protected;

export function CheckLoggedin({ children }) {
  const getUser = JSON.parse(localStorage.getItem('user_pro'));
  const authToken = getCookie("authToken");
  const isLoggedIn = getUser && authToken && (getUser.success || getUser.sucess);
  
  return isLoggedIn ? <Navigate to="/dashboard" replace /> : children;
}