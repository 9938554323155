import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import moment from 'moment';

const getLastTwelveMonths = () => {
    const months = [];
    for (let i = 0; i < 12; i++) {
        const monthName = moment()
            .subtract(i, 'months')
            .locale('fr')
            .format('MMMM');
        months.unshift(monthName);
    }
    return months;
};

export default function DashboardGraph({ data = [] }) {
    //const xLabels = React.useMemo(() => getLastTwelveMonths(), []);

    const xLabels = React.useMemo(() => {
        return [...data].map(item => item.publish_month_name+" "+item.publish_year || 0);
    }, [data]);

    const monthlyData = React.useMemo(() => {
        return [...data].map(item => item.count || 0);
    }, [data]);

    const valueFormatter = (val) => {
        return val !== null ? val.toString() : ''
    }
    
    return (
        <BarChart
            borderRadius={10}
            width={1000}
            height={500}
            className='braWidthHeight'
            margin={{ left: 50, right: 50, top: 20, bottom: 70 }}
            series={[
                { 
                    data: monthlyData,
                    color: '#27295B',
                    label: 'Activités mensuelles'
                },
            ]}
            xAxis={[{
                data: xLabels,
                scaleType: 'band',
                categoryGapRatio: 0.6,
                barGapRatio: 0.1,
                tickFontSize: 14,
                tickLabelStyle: {
                    angle: 45,
                    textAnchor: 'start',
                    transform: 'translate(0, 8px)'
                }
            }]}
            yAxis={[{
                valueFormatter,
                tickInterval: 1,
                tickMaxStep: 6,
                tickLabelStyle: {
                    fontSize: 14
                },
            }]}
        />
    );
}
