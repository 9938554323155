import React, { useState, useEffect } from "react";
import WebRoutes from "./Routes/WebRoutes";
import { useDispatch } from "react-redux";
import { userActions } from "./_actions";
import { getCookie } from "./_helpers/cookie";

export default function App() {
  const dispatch = useDispatch();
  const authToken = getCookie("authToken");
  const [isValidating, setIsValidating] = useState(true);

  useEffect(() => {
    const validateSession = async () => {
      if (!authToken) {
        setIsValidating(false);
        return;
      }

      try {
        const result = await dispatch(userActions.userSession({ token: authToken }));
        if (!result?.sucess && !result?.success) {
          // Clear all data if session is invalid
          localStorage.removeItem("id");
          localStorage.removeItem("email");
          localStorage.removeItem("is_login");
          localStorage.removeItem("user_pro");
          localStorage.removeItem("userToken");
          document.cookie = `authToken=; path=/; domain=${process.env.REACT_APP_SUB_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:01 UTC; secure; SameSite=None`;
        }
      } catch (error) {
        // Clear all data on error
        localStorage.removeItem("id");
        localStorage.removeItem("email");
        localStorage.removeItem("is_login");
        localStorage.removeItem("user_pro");
        localStorage.removeItem("userToken");
        document.cookie = `authToken=; path=/; domain=${process.env.REACT_APP_SUB_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:01 UTC; secure; SameSite=None`;
      } finally {
        setIsValidating(false);
      }
    };

    validateSession();
  }, [authToken, dispatch]);

  if (isValidating) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return <WebRoutes />;
}
