import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsEnvelopeCheckFill, BsArrowRight } from 'react-icons/bs';

const ThankYouPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { email } = location.state || {};

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white shadow sm:rounded-lg overflow-hidden">
          {/* Header */}
          <div className="px-4 py-8 sm:p-8 border-b border-gray-200">
            <div className="text-center">
              <BsEnvelopeCheckFill className="mx-auto h-16 w-16 text-green-500" />
              <h3 className="mt-4 text-2xl font-semibold text-gray-900">
                Merci pour votre délégation!
              </h3>
              <p className="mt-2 text-base text-gray-600">
                Un e-mail a été envoyé avec succès à {email && <span className="font-medium">{email}</span>}
              </p>
            </div>
          </div>

          {/* Content */}
          <div className="px-4 py-6 sm:p-6">
            <div className="space-y-4 text-center">
              <p className="text-gray-600">
                Le destinataire recevra un lien de paiement pour compléter la transaction.
              </p>
              <p className="text-gray-600">
                Vous pouvez suivre l'état de votre annonce dans votre tableau de bord.
              </p>
            </div>
          </div>

          {/* Actions */}
          <div className="px-4 py-5 sm:p-6 bg-gray-50 flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            <button
              onClick={() => navigate('/dashboard')}
              className="inline-flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors duration-200"
            >
              Aller au tableau de bord
              <BsArrowRight className="ml-2 h-5 w-5" />
            </button>
          </div>
        </div>

        {/* Additional Information */}
        <div className="mt-8 text-center text-sm text-gray-500">
          <p>
            Si vous avez des questions, n'hésitez pas à contacter notre service client.
          </p>
        </div>
      </div>
    </div>
  );
};
export default ThankYouPage;
