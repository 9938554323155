import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import EsignModal from "../../components/eSignModal";
import { alertActions } from "../../_actions";

export default function ShareDocumentModal({
  isModalOpen,
  setIsModalOpen,
  fetchDossiers,
  shareItem,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    console.log("Share item:", shareItem);
    const body = {
      ...formData,
      documentId: shareItem.id,
      userId: shareItem.user_id,
    };
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/prouser/send-signature-invite`,
        body
      )
      .then((response) => {
        console.log("Share successful:", response.data);
        dispatch(alertActions.success(response?.data?.message));
        setIsLoading(false);
        setIsModalOpen(false);
        // fetchDossiers();
        setError(null);
      })
      .catch((error) => {
        console.error("Share failed:", error);
        setError("Failed to share dossier");
        setIsLoading(false);
      });
    // Add form submission logic here
  };
  return (
    <EsignModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <div className="w-full max-w-md">
        <h2 className="text-center text-xl font-semibold text-gray-800 mb-2">
          Invitez autant de personnes que vous le souhaitez.
        </h2>
        <p className="text-center text-gray-600 text-sm mb-6">
          Les personnes que vous invitez recevront un lien pour consulter votre
          document, mais elles ne pourront pas le modifier ni le signer.
        </p>
        {error && <p className="text-[#f5424e]">{error}</p>}
        <form className="space-y-5">
          <div>
            <label htmlFor="name" className="block text-gray-800 font-medium">
              Nom
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none bg-gray-50"
              placeholder="Entrez le nom"
              required
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-gray-800 font-medium">
              E-mail
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none bg-gray-50"
              placeholder="Entrez l'adresse e-mail"
              required
            />
          </div>

          <div>
            <label
              htmlFor="message"
              className="block text-gray-800 font-medium"
            >
              Message (facultatif)
            </label>
            <textarea
              name="message"
              id="message"
              rows="4"
              maxLength="600"
              value={formData.message}
              onChange={handleChange}
              className="mt-1 w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none bg-gray-50 resize-none"
              placeholder="Merci d'avance pour l'examen de mon document !"
            ></textarea>
            <div className="text-right text-xs text-gray-400">
              {formData.message.length}/600
            </div>
          </div>
          <div className="mt-4 flex gap-4 justify-end">
            <button
              className="bg-[#979797] text-white px-6 py-2 rounded hover:bg-[#979797]"
              onClick={() => setIsModalOpen(false)}
            >
              Annuler
            </button>
            <button
              onClick={handleSubmit}
              className={`bg-secondary text-white px-6 py-2 rounded hover:bg-primary ${
                formData.name === "" || formData.email === ""
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              disabled={
                formData.name === "" || formData.email === "" || isLoading
              }
            >
              {isLoading ? "Envoi en cours..." : "Envoyer l'invitation"}
            </button>
          </div>
        </form>
      </div>
    </EsignModal>
  );
}
