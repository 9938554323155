import React from "react";
import { ImCancelCircle } from "react-icons/im";

const EsignModal = ({ isOpen, onClose, children, className }) => {
  if (!isOpen) return null;

  return (
    <div className={"fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-500090"} style={{zIndex: 1002}}>
      <div className={className ? className : "bg-white relative rounded-lg p-6  shadow-lg shadow-black z-50007"}>
        <button
          onClick={onClose}
          className="absolute right-[1rem] top-[0.5rem] self-end text-red-200 hover:text-blue-100"
        >
          <ImCancelCircle />
        </button>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default EsignModal;
