import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertActions } from "../_actions";
import { useNavigate } from "react-router-dom";
import PageLoaderComp from "../component/pageLoaderComp";
import axios from "axios";
const DocumentStatus = ({ uploadedFile }) => {
  const getStatusStyles = () => {
    switch (uploadedFile?.status) {
      case "2":
        return "text-[#008000]";
      case "1":
        return "text-[#ff0000]";
      default:
        return "text-[#7d7c7c]";
    }
  };
  const getStatustext = () => {
    switch (uploadedFile?.status) {
      case "2":
        return "validé";
      case "1":
        return "refusé";
      default:
        return "en attente";
    }
  };
  return (
    <div className="flex item-start sm:items-center sm:space-x-2">
      <span className={`flex item-start sm:items-center ${getStatusStyles()}`}>
        <span className="w-2 h-2 rounded-full bg-current mr-2" />
        {getStatustext()}
      </span>
    </div>
  );
};

const DocumentList = ({ documents, currentDossier, createurl,newdocuments }) => {
  const dispatch = useDispatch();
  const [isQuestionnaireExpanded, setIsQuestionnaireExpanded] = useState(false);
  const [isJustificatifsExpanded, setIsJustificatifsExpanded] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newDocName, setNewDocName] = useState("");
  const [newDocDescription, setNewDocDescription] = useState("");
  const [localDocuments, setLocalDocuments] = useState(documents);
  const [newlyAddedDocs, setNewlyAddedDocs] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState('');

  // Keep localDocuments in sync with documents prop and preserve uploaded files
  useEffect(() => {
    const newDocs = Array.from(newlyAddedDocs)
      .map((docName) => localDocuments.find((doc) => doc.name === docName))
      .filter(Boolean);
    
    // Preserve uploaded file information and handle newly added docs from API
    const docsWithUploads = documents.map(doc => ({
      ...doc,
      uploaded_file: doc.uploaded_file || null
    }));

    // Get newly added docs from API
    // console.log('doc.uploaded_file.isnewlyadded',newdocuments);
    const apiNewDocs = newdocuments.filter(doc => doc && doc.isnewlyadded === 'true')
      .map(doc => ({
        name: doc.docId,
        title: doc.file_title || doc.title,
        status: doc.status,
        uploaded_file: doc,
        description: '',
        error: null
      }));

    // Combine all documents
    const combinedDocs = [
      ...docsWithUploads,
      ...newDocs,
      ...apiNewDocs
    ];

    // Remove duplicates based on name
    const uniqueDocs = combinedDocs.reduce((acc, current) => {
      const x = acc.find(item => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      }
      return acc;
    }, []);
    
    setLocalDocuments(uniqueDocs);
    // Update newlyAddedDocs Set with docs from API
    setNewlyAddedDocs(new Set([
      ...Array.from(newlyAddedDocs),
      ...apiNewDocs.map(doc => doc.name)
    ]));
  }, [documents]);

  const handleFileChange = async (docId, event) => {
    const file = event.target.files[0];
    if (file) {
      let doc = null;
      try {
        setLoading(true);
        // Check both documents and localDocuments (which includes newly added docs)
        doc = documents.find(d => d.name === docId) || 
              (newlyAddedDocs.has(docId) && localDocuments.find(d => d.name === docId));
        if (!doc) {throw new Error('Document not found');}
        // Store file in state
        setSelectedFiles(prev => ({...prev,[docId]: file}));

        // Create FormData and upload immediately
        const formData = new FormData();
        formData.append('file', file);
        formData.append(`file_title`, doc.title);
        formData.append(`docId`, docId);
        formData.append("dossierId", currentDossier.id);
        // Add flag to indicate if this is a newly added document
        formData.append("isnewlyadded", newlyAddedDocs.has(docId));
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/prouser/dossiers-upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.success) {
          dispatch(alertActions.success(`${doc.title} téléchargé avec succès`));
        }
      } catch (error) {
        console.error("Upload failed:", error);
        const errorMessage = doc ? `Erreur lors du téléchargement de ${doc.title}` : "Erreur lors du téléchargement";
        dispatch(alertActions.error(errorMessage));
        // Remove file from state if upload failed
        setSelectedFiles(prev => {
          const newFiles = { ...prev };
          delete newFiles[docId];
          return newFiles;
        });
      } finally {
        setLoading(false);
      }
    }
  };
  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setLoading(true);
      const formData = new FormData();
      formData.append('status', 2);
      formData.append('dossierId', currentDossier.id);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/prouser/dossiers-submit`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      
      if (response.data.success) {
        dispatch(alertActions.success('Dossier soumis avec succès'));
        navigate('/prouser/mes-dossiers');
      }
    } catch (error) {
      console.error('Submit failed:', error);
      dispatch(alertActions.error('Erreur lors de la soumission du dossier'));
    } finally {
      setLoading(false);
      setIsSubmitting(false);
    }
  };

  const handleLinkClick = (e, url) => {
    e.preventDefault();
    setSelectedUrl(url);
    setShowConfirmModal(true);
  };
  const handleConfirm = () => {
    window.open(selectedUrl, '_blank');
    setShowConfirmModal(false);
  };
  const handleRemoveDocument = (docName) => {
    setLocalDocuments((prev) => prev.filter((doc) => doc.name !== docName));
    setNewlyAddedDocs((prev) => {
      const newSet = new Set(prev);
      newSet.delete(docName);
      return newSet;
    });
    setSelectedFiles((prev) => {
      const newFiles = { ...prev };
      delete newFiles[docName];
      return newFiles;
    });
    dispatch(alertActions.success("Document supprimé avec succès"));
  };
 //console.log('localDocuments',localDocuments);
  return (
    <>
      {loading ? <PageLoaderComp /> : ""}
      <div className="mt-8">
        {/* Questionnaire Accordion */}
        <div className="bg-white border rounded-lg mb-4">
          <div className="p-4 flex items-center justify-between border-b">
            <div className="flex items-center">
              <svg
                className="w-5 h-5 mr-2 text-gray-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                <path
                  fillRule="evenodd"
                  d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-gray-900 font-medium">Questionnaire</span>
            </div>
            <button
              className="text-red-600 text-xs sm:text-sm font-medium flex items-center"
              onClick={() =>
                setIsQuestionnaireExpanded(!isQuestionnaireExpanded)
              }
            >
              À modifier
              <svg
                className={`w-4 h-4 ml-1 transform transition-transform ${
                  isQuestionnaireExpanded ? "rotate-180" : ""
                }`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>

          {isQuestionnaireExpanded && (
            <div>
              <div className="p-4">
                <p className="text-[#1E1E1E] text-sm">
                  Le nom de la société mandataire "NEXTGEN" ne figure pas dans
                  le Mandat du client.
                </p>
              </div>
              <div className="p-4 flex justify-center">
                <button
                  // onClick={() => window.open(createurl, "_blank")}
                  onClick={(e) => handleLinkClick(e, createurl)} 
                  className="w-full sm:w-auto px-6 py-2.5 bg-[#2563EB] text-white text-sm font-medium rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Confirmer mes réponses
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Justificatifs Accordion */}
        <div className="bg-white border rounded-lg">
          <div className="p-4 flex items-center justify-between border-b">
            <div className="flex items-center">
              <svg
                className="w-5 h-5 mr-2 text-gray-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-gray-900 font-medium">Justificatifs</span>
            </div>
            <button
              className="text-red-500 text-xs sm:text-sm font-medium flex items-center"
              onClick={() =>
                setIsJustificatifsExpanded(!isJustificatifsExpanded)
              }
            >
              {isJustificatifsExpanded
                ? "Cliquez pour réduire"
                : "Cliquez pour afficher"}
              <svg
                className={`w-4 h-4 ml-1 transform transition-transform ${
                  isJustificatifsExpanded ? "rotate-180" : ""
                }`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>

          {isJustificatifsExpanded && (
            <div className="p-4">
              <form>
                <div className="space-y-4">
                  {localDocuments.map((doc, index) => (
                    <div
                      key={index}
                      className="flex items-center flex-col sm:flex-row justify-between p-2 sm:p-4 border rounded-lg"
                    >
                      <div className="flex item-start w-full sm:items-center space-x-4">
                        <div className="flex-1">
                          <h4 className="text-left text-sm sm:text-lg font-medium text-navy-900">
                            {doc.title} 
                          </h4>
                          {doc.error && (
                            <p className="text-sm text-red-500 mt-1">
                              {doc.error}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center justify-between w-full sm:space-x-4">
                        <DocumentStatus uploadedFile={doc.uploaded_file} />
                        {doc.uploaded_file && doc.uploaded_file.file && (
                          <>
                            {(() => {
                              const fileData = JSON.parse(doc.uploaded_file.file);
                           //  console.log("doc.uploaded_file---",doc.uploaded_file);
                              return (
                                <a
                                  href={`${process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE}assets/uploads/${fileData[0].filename}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-blue-500 hover:text-blue-600"
                                >
                                  <svg
                                    className="w-5 h-5 mr-2 text-gray-600"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </a>
                              );
                            })()}
                          </>
                        )}
                        {newlyAddedDocs.has(doc.name) && (
                          <button type="button"
                            onClick={() => handleRemoveDocument(doc.name)}
                            className="text-red-500 hover:text-red-600"
                          >
                            Supprimer
                          </button>
                        )}
                        <div className="relative flex items-center">
                          {selectedFiles[doc.name] && (
                            <span className="ml-2 text-sm text-gray-600">
                              {selectedFiles[doc.name].name}
                            </span>
                          )}
                          <input
                            type="file"
                            accept=".pdf"
                            id={doc.name}
                            name={doc.name}
                            onChange={(e) => handleFileChange(doc.name, e)}
                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                            title="Upload file"
                          />

                          <div className="p-2 hover:bg-gray-100 rounded-full">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className={`size-6 ${selectedFiles[doc.name] ? 'text-green-500' : ''}`}
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                strokeWidth="2"
                                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                              />
                            </svg>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="mt-6 text-center">
                  <div className="mb-4">
                    <div className="flex justify-between text-sm text-gray-600 mb-2">
                      <span>Progression</span>
                      <span>
                        {localDocuments.filter(doc => doc.uploaded_file || selectedFiles[doc.name]).length} /{" "}
                        {localDocuments.length} justificatifs uploadés
                      </span>
                    </div>
                    <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-secondary transition-all duration-300 rounded-full"
                        style={{
                          width: `${
                            (localDocuments.filter(doc => doc.uploaded_file || selectedFiles[doc.name]).length /
                              localDocuments.length) *
                            100
                          }%`,
                          transition: "width 0.3s ease-in-out",
                          minWidth:
                            localDocuments.filter(doc => doc.uploaded_file || selectedFiles[doc.name]).length > 0
                              ? "2rem"
                              : "0",
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row justify-center gap-4">
                    <button
                      type="button"
                      onClick={() => setShowModal(true)}
                      className="px-4 py-2 border rounded-lg transition-colors bg-white border-blue-500 text-blue-500 hover:bg-blue-50"
                    >
                      Joindre un justificatif
                    </button>
                    <button
                      type="button"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      className={`px-4 py-2 border rounded-lg transition-colors ${
                        isSubmitting
                          ? "bg-gray text-white border-gray cursor-not-allowed"
                          : "bg-secondary border-blue-500 hover:bg-primary text-white"
                      }`}
                    >
                      {isSubmitting ? "Soumission en cours..." : "Soumettre pour validation"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>

        {/* Modal for adding new document */}
        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">
                  Ajout d'un justificatif supplémentaire
                </h2>
                <button
                  onClick={() => setShowModal(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="docName"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Nom *
                  </label>
                  <input
                    type="text"
                    id="docName"
                    value={newDocName}
                    onChange={(e) => setNewDocName(e.target.value)}
                    maxLength={50}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    required
                  />
                  <div className="text-right text-xs text-gray-500 mt-1">
                    {newDocName.length}/50
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="docDescription"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Description
                  </label>
                  <input
                    type="text"
                    id="docDescription"
                    value={newDocDescription}
                    onChange={(e) => setNewDocDescription(e.target.value)}
                    placeholder="facultatif"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                </div>
                <div className="flex justify-end gap-3 mt-6">
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
                  >
                    Annuler
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      // Create new document object
                      const newDoc = {
                        name: newDocName.toLowerCase().replace(/\s+/g, "_"),
                        title: newDocName,
                        description: newDocDescription,
                        status: "",
                        error: null,
                      };

                      // Add new document to the array
                      setLocalDocuments((prev) => [...prev, newDoc]);

                      // Mark document as newly added
                      setNewlyAddedDocs(
                        (prev) => new Set([...prev, newDoc.name])
                      );

                      // Reset form and close modal
                      setShowModal(false);
                      setNewDocName("");
                      setNewDocDescription("");

                      // Show success message
                      dispatch(
                        alertActions.success("Document ajouté avec succès")
                      );
                    }}
                    disabled={!newDocName.trim()}
                    className={`px-4 py-2 rounded-lg ${
                      !newDocName.trim()
                        ? "bg-gray text-white cursor-not-allowed"
                        : "bg-secondary hover:bg-primary text-white"
                    }`}
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Confirmation Modal */}
    {showConfirmModal && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 w-full max-w-md">
          <h2 className="text-xl font-bold mb-4">Confirmation</h2>
          <p className="text-gray-600 mb-6">Êtes-vous sûr de vouloir continuer vers la form de modification ?</p>
          <p className="text-gray-600 mb-6">Des frais supplémentaires peuvent s'appliquer si les modifications affectent les conditions de paiement d'origine.</p>
          
          <div className="flex justify-end gap-3">
            <button
              onClick={() => setShowConfirmModal(false)}
              className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
            >
              Annuler
            </button>
            <button onClick={handleConfirm} class="text-base font-semibold text-white flex items-center justify-center px-4 py-2 sm:w-auto w-full rounded-md bg-primary">
              Continuer
            </button>
          </div>
        </div>
      </div>
    )}
    </>
  );
};

export default DocumentList;
