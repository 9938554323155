import axios from 'axios';
import { LogoutApp } from '../../../_helpers';

export const getResponse = async (endpoint, params) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}${endpoint}${params}`);
        if (response.status === 200) {
            console.log(response)
            const dt = JSON.stringify(response.data.data[0])
            localStorage.setItem('user_pro', dt)
            // console.log(dt)
            return response;
        } else {
            console.log(response)
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401 && error.response.data.success === false) {
            console.log("LogoutApp==");
            LogoutApp();
        }
    }
}

export const getAllDataResponse = async (endpoint) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}${endpoint}`);
        console.log(response)
        if (response.status === 200) {
            console.log(response)
            return response;
        } else {
            console.log(response)
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401 && error.response.data.success === false) {
            console.log("LogoutApp==");
            LogoutApp();
        }
    }
}

export const updateResponse = async (endpoint, data, params) => {
    try {
        // Get the token from localStorage
        const token = localStorage.getItem('userToken');

        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}${endpoint}`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                },
            }
        );
        return response;    
        /* if (response.status === 204) {
            // console.log(response);
            return response;
        } else {
            // console.log(response);
        } */
    } catch (error) {
        console.error('Error fetching data:========LogoutApp', error);
        if (error.response && error.response.status === 401 && error.response.data.success === false) {
            console.log("LogoutApp==");
            LogoutApp();
        }

    }
};


export const updateLogoResponse = async (endpoint, data, params) => {
    try {
        // Get the token from localStorage
        const token = localStorage.getItem('userToken');

        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}${endpoint}`,
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                },
            }
        );
        return response;    
       /*  if (response.status === 204) {
            console.log(response);
            return response;
        } else {
            console.log(response);
        } */
    } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401 && error.response.data.success === false) {
            console.log("LogoutApp==");
            LogoutApp();
        }
    }
};


