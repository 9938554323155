import React, { useState, useRef, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { alertActions } from "../_actions";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import SignatureCanvas from "react-signature-canvas";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import axios from "axios";
import { FaFileSignature } from "react-icons/fa";
import { CiText } from "react-icons/ci";
import { FaCalendarDays } from "react-icons/fa6";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { Resizable } from "re-resizable";
import Draggable from "react-draggable";
import { FaCog } from "react-icons/fa";
import { BiExpandAlt } from "react-icons/bi";
import { PDFDocument } from "pdf-lib"; // Import pdf-lib to edit PDFs
import Layout from "./Layout";
import EsignModal from "../components/eSignModal";
import DemoGuide from "../component/modals/demoGuide";
import ShareDocumentModal from "../component/modals/shareDocumentModal";
import FileViewer from "react-file-viewer";
const SignerDesDocuments = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // State to toggle between view and edit mode
  const [filePreview, setFilePreview] = useState(null); // State to store the signature preview
  const sigPad = useRef(null);
  const dispatch = useDispatch();
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [showPopup, setShowPopup] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [activeInput, setActiveInput] = useState(null);
  const [inputs, setInputs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [isDemoOpen, setIsDemoOpen] = useState(false);
  const [docId, setDocId] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [fileType, setFileType] = useState("");
  const handleNext = () => {
    setIsNext(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePdfClick = (e) => {
    const viewer = document.querySelector(".rpv-core__viewer");
    const pagesContainer = document.querySelector(".rpv-core__inner-pages"); // double-check this in DOM!

    if (!viewer || !pagesContainer) return;

    const rect = pagesContainer.getBoundingClientRect();
    const rectViewer = viewer.getBoundingClientRect();
    const x = e.clientX - rectViewer.left;
    const y = e.clientY - rectViewer.top;

    // Send position with viewer dimensions
    const position = {
      x: x,
      y: y,
      viewerWidth: rect.width, // Width of the PDF viewer
      viewerHeight: rect.height, // Height of the PDF viewer
    };
    const scrollTop = pagesContainer.scrollTop;
    const scrollLeft = pagesContainer.scrollLeft;

    // const x = e.clientX - rect.left + scrollLeft;
    // const y = e.clientY - rect.top + scrollTop;

    setPopupPosition(position);
    setShowPopup(true);
  };

  const handleToolClick = (type) => {
    setShowPopup(false);
    const viewer = document.querySelector(".rpv-core__viewer");
    if (!viewer) return;

    const newInput = {
      id: Date.now(),
      type,
      position: popupPosition,
      content: "",
      size: { width: 200, height: 40 },
    };
    setInputs([...inputs, newInput]);
    setActiveInput(newInput.id);
  };

  const currentUser = useSelector((state) => state.authentication.user);
  if (!currentUser) {
    return <Navigate to="/" />;
  }

  // Handle file upload
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.name.split(".").pop().toLowerCase();
      if (!["pdf", "doc", "docx"].includes(fileType)) {
        setUploadError("Seuls les fichiers PDF, DOC et DOCX sont acceptés");
        return;
      }

      // const fileType = file.name.split(".").pop().toLowerCase();
      // if (!["pdf", "docx"].includes(fileType)) {
      //   setUploadError("Seuls les fichiers PDF et DOCX sont acceptés");
      //   return;
      // }
      if (file.size > 10 * 1024 * 1024) {
        setUploadError("La taille du fichier ne doit pas dépasser 10MB");
        return;
      }
      const formData = new FormData();
      formData.append("user_id", currentUser.data.id);
      // formData.append("document_id", id);
      formData.append("pdf", file);
      let startTime = Date.now();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/prouser/esign-des-documents`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            // Calculate the percentage progress based on file size
            const totalSize = progressEvent.total;
            const loaded = progressEvent.loaded;

            // Calculate percentage completed (0 to 100%)
            const percentCompleted = Math.round((loaded * 100) / totalSize);

            let startProgress = 0; // Start progress
            const targetProgress = 100; // End progress
            const duration = 1500; // 2 seconds in milliseconds
            const stepTime = 100; // Time between each step (milliseconds)
            const steps = duration / stepTime; // Number of steps in the duration
            const stepProgress = (targetProgress - startProgress) / steps; // Progress increment per step

            // Gradually increase progress
            let currentProgress = startProgress;

            const interval = setInterval(() => {
              currentProgress += stepProgress;
              if (currentProgress >= percentCompleted) {
                currentProgress = percentCompleted;
                clearInterval(interval); // Stop once the target is reached
              }
              setUploadProgress(Math.round(currentProgress)); // Update the progress state
            }, stepTime);
          },
        }
      );
      if (response.status === 200 && response.data.success) {
        console.log("response", response?.data?.filename);
        setDocId(response.data.id);
        const byteCharacters = atob(response.data.file_attachment); // Decode the Base64 string
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const slice = byteCharacters.slice(offset, offset + 1024);
          const byteNumbers = new Array(slice.length);

          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: "application/pdf" });
        setIsDemoOpen(true);
        setSelectedFile(blob);
        setFilePreview(response?.data?.filename);
        setFileType(fileType);
        // Create a URL for the Blob and set it to the state
        setFile(URL.createObjectURL(blob));
      }
      // setSelectedFile(file);
      // setFile(URL.createObjectURL(file));
    }
  };

  const uploadFile = async () => {
    setUploading(true);
    setUploadError(null);
    setUploadSuccess(false);
    setUploadProgress(0);
    setTimeRemaining(null);

    try {
      // Convert signature canvas to blob
      // const signatureDataUrl = sigPad.current.toDataURL('image/png');
      const signatureBlob = await fetch(signatureImage).then((res) =>
        res.blob()
      );
      const signatureFile = new File([signatureBlob], "signature.png", {
        type: "image/png",
      });
      const formData = new FormData();
      formData.append("user_id", currentUser.data.id);
      formData.append("document_id", docId);
      formData.append("signature", signatureFile);
      formData.append("pdf", selectedFile);
      formData.append("position", JSON.stringify(inputs));
      formData.append("fileType", fileType); // Send file type to backend

      let startTime = Date.now();
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}api/prouser/update-document`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          // onUploadProgress: (progressEvent) => {
          //   const percentCompleted = Math.round(
          //     (progressEvent.loaded * 100) / progressEvent.total
          //   );
          // setUploadProgress(percentCompleted);

          // const timeElapsed = Date.now() - startTime;
          // const uploadSpeed = progressEvent.loaded / (timeElapsed / 1000);
          // const remainingBytes = progressEvent.total - progressEvent.loaded;
          // const estimatedTimeRemaining = remainingBytes / uploadSpeed;
          // setTimeRemaining(estimatedTimeRemaining.toFixed(2));
          // },
        }
      );

      if (response.status === 200) {
        setUploadSuccess(response?.data?.message);
        // setFile(null);
        dispatch(alertActions.success(response?.data?.message));
        // sigPad.current.clear();
        setSignatureImage(null);
        setUploadError(null);
      } else {
        setUploadError(
          "Erreur lors du téléchargement du fichier. Veuillez réessayer."
        );
      }
    } catch (error) {
      console.error("Upload error:", error);
      setUploadError(
        "Erreur lors du téléchargement du fichier. Veuillez réessayer."
      );
    } finally {
      setUploading(false);
    }
  };

  const handleDragStop = (id, e, data) => {
    const { x, y } = data;
    setInputs(
      inputs.map((input) =>
        input.id === id ? { ...input, position: { x, y } } : input
      )
    );
  };

  const handleResize = (id, direction, ref, d) => {
    setInputs(
      inputs.map((input) =>
        input.id === id
          ? {
              ...input,
              size: {
                width: input.size.width + d.width,
                height: input.size.height + d.height,
              },
            }
          : input
      )
    );
  };

  const handleInputChange = (id, value) => {
    setInputs(
      inputs.map((input) =>
        input.id === id ? { ...input, content: value } : input
      )
    );
  };

  const handleEditPdf = async () => {
    if (!file) {
      alert("Please select a PDF file");
      return;
    }

    if (!sigPad.current || sigPad.current.isEmpty()) {
      alert("Please sign the document before editing.");
      return;
    }

    // Load the selected PDF
    const existingPdfBytes = await fetch(file).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const page = pdfDoc.getPages()[0];
    const { width, height } = page.getSize();

    // Get signature data as DataURL
    const signatureDataUrl = sigPad.current.toDataURL("image/png");

    if (!signatureDataUrl || signatureDataUrl === "data:,") {
      alert("Signature is empty. Please sign the document.");
      return;
    }

    // Convert the signature DataURL to a Blob
    const signatureBlob = await fetch(signatureDataUrl).then((res) =>
      res.blob()
    );
    const signatureArrayBuffer = await signatureBlob.arrayBuffer();
    const signatureUint8Array = new Uint8Array(signatureArrayBuffer);

    // Embed the PNG signature image into the PDF
    const signatureImage = await pdfDoc.embedPng(signatureUint8Array);
    setSignatureImage(signatureDataUrl);
    handleCloseModal();
  };
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      background: "linear-gradient(94.46deg, #27295B 0%, #1CBE93 110.28%)", // Add the gradient here
      ...theme.applyStyles("dark", {
        background: "linear-gradient(94.46deg, #27295B 0%, #1CBE93 110.28%)", // Add the gradient here for dark mode
      }),
    },
  }));

  return (
    <Layout>
      <div className="mb-8 col-span-12 xl:col-span-9">
        <div className="xl:max-w-[1126px] w-full h-full bg-white md:rounded-[20px]">
          <div className="mainHead min-h-[80px] sm:min-h-[120px] flex sm:flex-row flex-col sm:items-center p-3 sm:p-10 sm:justify-between">
            <h1 className="text-lg sm:text-xl md:text-lg xl:text-xl 2xl:text-xl font-extrabold text-white sm:mt-0 mt-4">
              Signer des documents
            </h1>
            <div className="flex gap-4">
              <Link to="/prouser/esign-des-documents">
                <button className="bg-blue-500 text-white py-2 px-4 rounded">
                  Listes de documents
                </button>
              </Link>
              {/* <button
                className="bg-green-500 text-white py-2 px-4 rounded"
                onClick={toggleEditMode}
              >
                {isEditing ? "Exit Edit Mode" : "Edit PDF"}
              </button> */}
            </div>
          </div>
          <div className="p-6 flex flex-col items-center">
            {/* <div style={{ width: "75%" }} className="flex direction-row my-4">
                    <BorderLinearProgress  variant="determinate" value={75} />
                    </div> */}
            <div className="w-full max-w-4xl mx-auto">
              <div
                className="mb-8 relative  flex justify-center  flex-col"
                style={{ height: "auto" }}
              >
                <h2 className="text-2xl font-bold text-center mb-4">
                  Signer des documents
                </h2>
                <p className="text-gray-600 text-center mb-12">
                  Conservez des documents en toute sécurité et signez en ligne
                </p>
                {/* <BorderLinearProgress variant="determinate" value={50} /> */}
                {uploadProgress > 0 && !file ? (
                  <div style={{ width: "75%" }} className=" mx-auto">
                    <BorderLinearProgress
                      variant="determinate"
                      value={uploadProgress}
                    />
                  </div>
                ) : null}
              </div>
              {file && isNext && (
                <button
                  className="bg-secondary text-white px-6 py-2 mb-2 h-10 rounded hover:bg-primary flex self-end ml-auto mr-20"
                  onClick={() => setIsActionOpen(true)}
                >
                  Actions
                </button>
              )}
            </div>
            {(() => {
              if (file && file !== "") {
                return (
                  <div className="w-full max-w-4xl mx-auto">
                    <div className="mb-8 relative" style={{ height: "auto" }}>
                      <Worker
                        workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`}
                      >
                        <div
                          onClick={handlePdfClick}
                          className="relative h-full"
                        >
                          {/* <Viewer fileUrl={file} /> */}
                          {console.log("file", file)}
                          {fileType === "pdf" ? (
                            <Worker
                              workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`}
                            >
                              <Viewer fileUrl={file} />
                            </Worker>
                          ) : fileType === "doc" || fileType === "docx" ? (
                            // <FileViewer
                            //   fileType={fileType}
                            //   filePath={file}
                            // />
                            <div 
                            className="relative h-full" 
                            onClick={handlePdfClick}
                          >
                            <FileViewer
                              fileType={fileType}
                              filePath={file}
                              onError={(e) => console.log("FileViewer error:", e)}
                            />
                          </div>
                          ) : null}
                        </div>
                      </Worker>
                      {showPopup && !isNext && (
                        <div
                          className="absolute bg-[#fff] rounded-lg shadow-lg border border-gray-600 flex gap-[0.08rem] overflow-hidden"
                          style={{
                            left: `${popupPosition.x}px`,
                            top: `${popupPosition.y}px`,
                            // zIndex: 1000,
                          }}
                        >
                          <button
                            onClick={() => handleToolClick("signature")}
                            className="text-white bg-[#333] hover:bg-gray-200 p-2 flex flex-col items-center"
                            title="Draw"
                          >
                            <FaFileSignature className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => handleToolClick("text")}
                            className="text-white bg-[#333] hover:bg-gray-200 p-2 flex flex-col items-center"
                            title="Text"
                          >
                            <CiText className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => handleToolClick("date")}
                            className="text-white bg-[#333] hover:bg-gray-200 p-2 flex flex-col items-center"
                            title="Calendar"
                          >
                            <FaCalendarDays className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => setShowPopup(false)}
                            className="text-white bg-[#333] hover:bg-gray-200 p-2 flex flex-col items-center"
                            title="Delete"
                          >
                            <RiDeleteBin5Fill className="h-5 w-5" />
                          </button>
                        </div>
                      )}

                      {inputs.map((input) => (
                        <Draggable
                          key={input.id}
                          defaultPosition={input.position}
                          position={null}
                          onStop={(e, data) =>
                            handleDragStop(input.id, e, data)
                          }
                          disabled={isNext}
                          handle=".drag-handle"
                          bounds=".rpv-core__viewer"
                        >
                          <div
                            style={{
                              position: "absolute",
                              // zIndex: activeInput === input.id ? 1001 : 999,
                              transform: "translate(0, 0)",
                              top: 0,
                            }}
                            className="select-none"
                          >
                            <Resizable
                              size={{
                                width: input.size.width,
                                height: input.size.height,
                              }}
                              onResizeStop={(e, direction, ref, d) =>
                                handleResize(input.id, direction, ref, d)
                              }
                              minWidth={200}
                              minHeight={40}
                              handleStyles={{
                                bottomRight: {
                                  bottom: -4,
                                  right: -4,
                                  cursor: "se-resize",
                                },
                              }}
                              // direction={{ top: true, right: true, bottom: true, left: true }}
                              enable={isNext}
                              disabled={isNext}
                              handleComponent={{
                                bottomRight: (
                                  <div className="absolute bottom-[-8px] right-[-8px] w-4 h-4 cursor-se-resize">
                                    <BiExpandAlt className="text-gray-500" />
                                  </div>
                                ),
                              }}
                            >
                              <div
                                className={`relative border border-dashed ${
                                  activeInput === input.id
                                    ? "border-orange-400"
                                    : "border-gray-300"
                                } bg-white`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setActiveInput(input.id);
                                }}
                              >
                                {/* Toolbar */}
                                {!isNext && (
                                  <div className="absolute -top-8 right-0 bg-[#333] rounded-t-lg flex items-center gap-1 px-2 py-1">
                                    <button
                                      className="text-white hover:text-gray-300 p-1"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <FaCog className="w-4 h-4" />
                                    </button>
                                    <button
                                      className="text-white hover:text-gray-300 p-1"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setInputs(
                                          inputs.filter(
                                            (i) => i.id !== input.id
                                          )
                                        );
                                        setSignatureImage(null);
                                        setShowPopup(false);
                                        setIsNext(false);
                                        setActiveInput(null);
                                      }}
                                    >
                                      <RiDeleteBin5Fill className="w-4 h-4" />
                                    </button>
                                  </div>
                                )}

                                {/* Drag handle */}
                                {!isNext && (
                                  <div className="drag-handle absolute top-0 left-0 w-full h-6 cursor-move bg-transparent" />
                                )}

                                {/* Content */}
                                <div className="p-2">
                                  {input.type === "text" && (
                                    <input
                                      type="text"
                                      value={input.content}
                                      onChange={(e) =>
                                        handleInputChange(
                                          input.id,
                                          e.target.value
                                        )
                                      }
                                      className="w-full outline-none text-gray-700 placeholder-gray-400"
                                      placeholder="Enter text"
                                    />
                                  )}
                                  {input.type === "date" && (
                                    <input
                                      type="date"
                                      value={input.content}
                                      onChange={(e) =>
                                        handleInputChange(
                                          input.id,
                                          e.target.value
                                        )
                                      }
                                      className="w-full outline-none text-gray-700"
                                    />
                                  )}
                                  {input.type === "signature" &&
                                    // <div className="w-full h-full min-h-[80px] flex items-center justify-center">
                                    //   <span className="text-gray-400">
                                    //     BELAW PARTNERS
                                    //   </span>
                                    // </div>
                                    (signatureImage ? (
                                      <img
                                        src={signatureImage}
                                        alt="Signature"
                                        className="w-full h-6 outline-none text-gray-700 placeholder-gray-400"
                                      />
                                    ) : (
                                      <button
                                        type="text"
                                        value={input.content}
                                        onClick={() => {
                                          // handleInputChange(
                                          //   input.id,
                                          //   e.target.value
                                          // );
                                          setIsModalOpen(true);
                                        }}
                                        className="w-full outline-none text-gray-700 placeholder-gray-400"
                                        placeholder="Signature"
                                      >
                                        {input.content || "Signature"}
                                      </button>
                                    ))}
                                </div>
                              </div>
                            </Resizable>
                          </div>
                        </Draggable>
                      ))}
                    </div>
                    {uploadSuccess && (
                      <p className="text-[#4feb34] mt-2">{uploadSuccess}</p>
                    )}
                    {uploadError && (
                      <p className="text-[#ff4c30] mt-2">{uploadError}</p>
                    )}
                    {inputs.length > 0 && (
                      <div className="mt-4 flex gap-4 justify-end mr-[4rem]">
                        <button
                          onClick={() => {
                            setInputs([]);
                            setIsNext(false);
                            setUploadError(null);
                            setUploadSuccess(null);
                          }}
                          // className="bg-[#ff4c30] hover:bg-[#ff3c20] text-white font-medium px-6 py-3 rounded-lg"
                          className="bg-[#979797] text-white px-6 py-2 rounded hover:bg-[#979797]"
                          // onClick={() => sigPad.current.clear()}
                        >
                          Annuler
                        </button>
                        <button
                          onClick={
                            signatureImage && isNext ? uploadFile : handleNext
                          }
                          // className="bg-[#ff4c30] hover:bg-[#ff3c20] text-white font-medium px-6 py-3 rounded-lg"
                          className="bg-secondary text-white px-6 py-2 rounded hover:bg-primary"
                          // onClick={uploadFile}
                          disabled={uploading}
                        >
                          {signatureImage
                            ? uploading
                              ? "Envoi en cours..."
                              : "sauvegarder"
                            : "Étape suivante"}
                        </button>
                      </div>
                    )}
                  </div>
                );
              } else {
                return (
                  <div className="w-full max-w-2xl border-2 border-dashed border-gray-300 rounded-lg p-12 flex flex-col items-center">
                    <div className="mb-6">
                      <svg
                        className="w-16 h-16 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        />
                      </svg>
                    </div>
                    <p className="text-lg text-gray-600 mb-4">
                      Glissez et déposez ou
                    </p>
                    {selectedFile && (
                      <p className="text-blue-500 mt-2">{selectedFile.name}</p>
                    )}
                    <div className="relative">
                      <input
                        type="file"
                        onChange={handleFileChange}
                        accept=".pdf,.doc,.docx"
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      />
                      <button className="bg-[#ff4c30] hover:bg-[#ff3c20] text-white font-medium px-6 py-3 rounded-lg">
                        Choisir un document
                      </button>
                    </div>
                    <p className="text-sm text-gray-500 mt-4">
                      Format PDF, DOC ou DOCX. Taille maximum du fichier: 10MB
                    </p>
                    {uploading && (
                      <div className="mt-2 text-blue-500">
                        <p>Téléchargement en cours: {uploadProgress}%</p>
                        {timeRemaining && (
                          <p>Temps restant estimé: {timeRemaining} secondes</p>
                        )}
                        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                          <div
                            className="bg-blue-600 h-2.5 rounded-full"
                            style={{ width: `${uploadProgress}%` }}
                          ></div>
                        </div>
                      </div>
                    )}
                    {uploadSuccess && (
                      <p className="text-[#4feb34] mt-2">{uploadSuccess}</p>
                    )}
                    {uploadError && (
                      <p className="text-[#ff4c30] mt-2">{uploadError}</p>
                    )}
                  </div>
                );
              }
            })()}
          </div>
        </div>
      </div>
      <EsignModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div className="border-2 border-gray-300 rounded-lg p-4 mb-4">
          <p className="text-gray-600 mb-2">Signez ici:</p>
          <SignatureCanvas
            penColor="black"
            ref={sigPad}
            canvasProps={{
              className: "sigCanvas border border-gray-200",
              style: { width: "100%", height: "200px" },
            }}
          />
        </div>
        <div className="mt-4 flex gap-4 justify-end mr-[4rem]">
          <button
            className="bg-[#979797] text-white px-6 py-2 rounded hover:bg-[#979797]"
            onClick={() => sigPad.current.clear()}
          >
            Effacer la signature
          </button>
          <button
            onClick={handleEditPdf}
            className="bg-secondary text-white px-6 py-2 rounded hover:bg-primary"
            disabled={uploading}
          >
            Étape suivante
          </button>
        </div>
      </EsignModal>
      <EsignModal isOpen={isActionOpen} onClose={() => setIsActionOpen(false)}>
        <div className="border-2 border-gray-300 rounded-lg p-4 mb-4">
          <h2 className="text-3xl font-bold text-center mb-4">
            Signer votre document
          </h2>
          <p className="text-center text-gray-600 mb-8">
            Passons en revue et signons votre document.
          </p>

          <div className="flex flex-col gap-4">
            {/* Signer Maintenant Button */}
            <button
              onClick={() => {
                setIsModalOpen(true);
                setIsActionOpen(false);
              }}
              className="bg-secondary text-white px-6 py-2 rounded-full hover:bg-primary focus:outline-none"
            >
              Signer maintenant
            </button>

            {/* Poser une question juridique Button */}
            {/* <button className="border-2 border-secondary text-secondary px-6 py-2 rounded-full hover:bg-secondary focus:outline-none">
          Poser une question juridique
        </button> */}

            {/* Additional Action Buttons */}
            <div className="border-t pt-4 mt-6 space-y-4">
              <div className="flex items-center gap-2">
                <span className="text-gray-500">📧</span>
                <button
                  className="text-gray-600"
                  onClick={() => {
                    setIsActionOpen(false);
                    setIsShareOpen(true);
                  }}
                >
                  Envoyer en recommandé
                </button>
              </div>
              {/* <div className="flex items-center gap-2">
            <span className="text-gray-500">✍</span>
            <button className="text-gray-600">Modifier les champs de signature</button>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-gray-500">🔗</span>
            <button className="text-gray-600">Partagez le document</button>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-gray-500">📋</span>
            <button className="text-gray-600">Copier</button>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-gray-500">⏳</span>
            <button className="text-gray-600">Afficher l'historique</button>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-gray-500">✏️</span>
            <button className="text-gray-600">Renommer le document</button>
          </div> */}
            </div>
          </div>
        </div>
      </EsignModal>
      <ShareDocumentModal
        isModalOpen={isShareOpen}
        setIsModalOpen={() => setIsShareOpen(false)}
        // fetchDossiers={fetchDossiers}
        shareItem={{
          id: docId,
          user_id: currentUser.data.id,
        }}
      />
      <DemoGuide
        isModalOpen={isDemoOpen}
        setIsModalOpen={() => setIsDemoOpen(false)}
      />
    </Layout>
  );
};

export default SignerDesDocuments;
