import React, { useRef, useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';

function SignaturePad() {
    const [file, setFile] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    const sigPad = useRef({});

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setPdfFile(file);
            setFile(URL.createObjectURL(file));
        }
    };
    
    const handleUploadAndSign = () => {
        const signature = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
        const formData = new FormData();
        formData.append('pdf', pdfFile);
        formData.append('signature', signature);
        axios.post(`${process.env.REACT_APP_API_URL}api/prouser/upload-and-sign`, formData, {
            responseType: 'blob'  // Important for receiving the PDF back from the server
        }).then(response => {
            const fileURL = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', 'signedPdf.pdf'); // Any file name
            document.body.appendChild(link);
            link.click();
        }).catch(error => alert('Failed to upload and sign PDF'));
    };
    
    return (
        <div>
            <input type="file" onChange={handleFileChange} accept="application/pdf" />
            {(() => {
                if (file && file !== "") {
                    return (
                        <div style={{ height: '500px', width: '500px' }}>
                            <Worker workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`}>
                             <Viewer fileUrl={file} />
                            </Worker>
                            <SignatureCanvas penColor='black' ref={sigPad} canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} />
                            <button className='bg-blue-500 text-white px-4 py-2 rounded' onClick={() => sigPad.current.clear()}>Clear Signature</button>
                            <button className='bg-blue-500 text-white px-4 py-2 rounded' onClick={handleUploadAndSign}>Upload and Sign PDF</button>
                        </div>
                    );
                }
            })()}
            
        </div>
    );
}

export default SignaturePad;
