import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from './Layout';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FiCheckCircle, FiAlertCircle, FiClock } from 'react-icons/fi';
import DocumentList from '../components/DocumentList';
import PageLoaderComp from '../component/pageLoaderComp';

const Completeformalites = () => {
  const [dossiers, setDossiers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDocumentPopupOpen, setIsDocumentPopupOpen] = useState(false);
  const currentUser = useSelector(state => state.authentication.user);
  const { id } = useParams()

  const steps = [
    { number: 1, label: 'Dossier' },
    { number: 2, label: 'Analyse' },
    { number: 3, label: 'Modification' },
    { number: 4, label: 'Validation' },
  ];

  useEffect(() => {
    const fetchDossiers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/prouser/dossiers/${id}`);
        
        if (response.status === 200 && response.data.success) 
        {
          setDossiers(response.data.data);
        }
      } catch (err) {
        setError('Error fetching dossiers');
      } finally {
        setLoading(false);
      }
    };
    fetchDossiers();
  }, [currentUser, id]);
  
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (loading) return <PageLoaderComp />;
  
  if (error) return <div>{error}</div>;

  const getStatusIcon = (status) => {
    switch (status) {
      case '4':
        return <FiCheckCircle className="w-6 h-6 text-emerald-700" />;
      case '3':
        return <FiCheckCircle className="w-6 h-6 text-green-500" />;
      case '2':
        return <FiClock className="w-6 h-6 text-yellow-500" />;
      case '1':
        return <FiClock className="w-6 h-6 text-blue-500" />;
      case '5':
        return <FiAlertCircle className="w-6 h-6 text-red-500" />;
      default:
        return <FiClock className="w-6 h-6 text-gray-500" />;
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case '4':
        return 'Dossier finalisé';
      case '3':
        return 'Dossier validé';
      case '2':
        return 'Dossier soumis';
      case '1':
        return "En cours d'analyse";
      case '5':
        return 'Documents manquants';
      default:
        return 'En attente';
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'completed':
        return 'text-emerald-700';
      case 'validated':
        return 'text-green-500';
      case 'pending':
        return 'text-yellow-500';
      case 'processing':
        return 'text-blue-500';
      case 'rejected':
        return 'text-red-500';
      default:
        return 'text-gray-500';
    }
  };

  const documentSets = {
    //sarl/eurl-sas/sasu
    statutes: {name: 'statutes', title: 'Statutes', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    liste_des_souscripteurs: {name: 'liste_des_souscripteurs', title: 'Liste des souscripteurs', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    certificate_deposit_of_funds_sarl: {name: 'certificate_deposit_of_funds_sarl', title: 'Attestation de dépot des fonds(optionnel)', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    certificate_deposit_of_funds: {name: 'certificate_deposit_of_funds', title: 'Attestation de dépot des fonds', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    identity_document_legal_representative: {name: 'identity_document_legal_representative', title: 'Pièce d’identité du représentant légal', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    declaration_no_conviction_legal_representative:{name: 'declaration_no_conviction_legal_representative', title: 'Déclaration de non condamnation du représentant légal', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    vital_card_legal_representative:{name: 'vital_card_legal_representative', title: 'Carte Vital du représentant légal (optionnel)', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    authorization_setup_legal_representative:{name: 'authorization_setup_legal_representative', title: 'Autorisation d’installation au domicile du représentant légal', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    proof_address_legal_representative:{name: 'proof_address_legal_representative', title: 'Justificatif de domicile', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    domiciliation_contract:{name: 'domiciliation_contract', title: 'contrat de domiciliation', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    bail_commercial:{name: 'bail_commercial', title: 'Bail commercial', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
              
    legal_notice_incorporation:{name: 'legal_notice_incorporation', title: 'Annonce légale de constitution ', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    legal_notice_rental_management:{name: 'legal_notice_rental_management', title: 'Avis de location', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    legal_notice_purchase_of_business_assets:{name: 'legal_notice_purchase_of_business_assets', title: 'Annonce légale Achat de fonds de commerce', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    identity_document_of_other_legal_representatives:{name: 'identity_document_of_other_legal_representatives', title: 'Pièce d’identité des autres représentants légaux', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    declaration_no_conviction_other_legal_representative:{name: 'declaration_no_conviction_other_legal_representative', title: ' Déclaration de non-condamnations des autres représentants légaux', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    piece_identite_du_second_representant_legal_ou_associe:{name: 'piece_identite_du_second_representant_legal_ou_associe', title: 'Pièce d’identité du second représentant légal ou associé', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    declaration_non_condamnation_second_representant_legal:{name: 'declaration_non_condamnation_second_representant_legal', title: 'Déclaration de non-condamnation du second représentant légal ou associé', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    //sci

    piece_identite_cogerant:{name: 'piece_identite_cogerant', title: 'Pièce d’identité du co-gérant (optionel)', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    declaration_non_condamnation_cogerant:{name: 'declaration_non_condamnation_cogerant', title: 'Déclaration de non-condamnation du co-gérant(optionel)', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    kbis_moins_de_3_mois:{name: 'kbis_moins_de_3_mois', title: 'KBIS de moins de 3 mois (optionel)', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    //dissolution
    pv_de_dissolution:{name: 'pv_de_dissolution', title: 'PV de dissolution', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    pi_du_president:{name: 'pi_du_president', title: 'PI du Président', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    pouvoir:{name: 'pouvoir', title: 'Pouvoir', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    pi_associe:{name: 'pi_associe', title: 'PI Associé', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    dnc_associe:{name: 'dnc_associe', title: 'DNC associé', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    pi_du_tiers:{name: 'pi_du_tiers', title: 'PI du tiers', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},


    dnc_du_tiers:{name: 'dnc_du_tiers', title: 'DNC du tiers', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    justificatif_domicile_tiers:{name: 'justificatif_domicile_tiers', title: 'Justificatif de domicile du tiers', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    justificatif_adresse_liquidateur:{name: 'justificatif_adresse_liquidateur', title: 'Justificatif (facture EDF ou autre) de l’adresse du liquidateur', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},


    pv_de_liquidation:{name: 'pv_de_liquidation', title: 'PV de liquidation', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    bilan_de_cloture:{name: 'bilan_de_cloture', title: 'Bilan de cloture', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    attestation_urssaf:{name: 'attestation_urssaf', title: 'Attestation URSSAF', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    attestation_impot:{name: 'attestation_impot', title: 'Attestation Impôt', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

   // formalities
    pv_agoa:{name: 'pv_agoa', title: 'PV AGOA', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    propositions_affectation_resultat:{name: 'propositions_affectation_resultat', title: 'Propositions d’affectation de résultat', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    bilan:{name: 'bilan', title: 'Bilan', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    compte_de_resultat:{name: 'compte_de_resultat', title: 'Compte de résultat', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    declaration_confidentialite:{name: 'declaration_confidentialite', title: 'Déclaration de confidentialité', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    autre_document:{name: 'autre_document', title: 'Autre document', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    //modification

    pv:{name: 'pv', title: 'PV', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    statuts_maj:{name: 'statuts_maj', title: 'Statuts MAJ', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    piece_identite_representant_legal_actuel:{name: 'piece_identite_representant_legal_actuel', title: 'Pièce d’identité du représentant légal actuel', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    autre_document1:{name: 'autre_document1', title: 'Autre document', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    autre_document2:{name: 'autre_document2', title: 'Autre document', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},


    annonce_legale:{name: 'annonce_legale', title: 'annonce légale', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},


    annonce_legale_depart:{name: 'annonce_legale_depart', title: 'Départ', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    annonce_legale_arrivee:{name: 'annonce_legale_arrivee', title: 'Arrivée', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    //retirer_annonce_legale:{name: 'retirer_annonce_legale', title: 'RETIRER Annonce légale', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    

    justificatif_nouveau_siege:{name: 'justificatif_nouveau_siege', title: 'Justificatif nouveau siège', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    liste_des_sieges_anterieurs:{name: 'liste_des_sieges_anterieurs', title: 'Liste des sièges antérieurs ', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    piece_identite_nouveau_dirigeant:{name: 'piece_identite_nouveau_dirigeant', title: 'Pièce d’identité du nouveau dirigeant', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    declaration_non_condamnation_nouveau_dirigeant:{name: 'declaration_non_condamnation_nouveau_dirigeant', title: 'Déclaration de Non condamnation du nouveau dirigeant', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    autre_document3:{name: 'autre_document3', title: 'Autre document', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    autre_document4:{name: 'autre_document4', title: 'Autre document', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    autre_document5:{name: 'autre_document5', title: 'Autre document', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    };

  const currentDossier = dossiers && dossiers.length > 0 ? dossiers[0] : null;
    let createurl = `${process.env.REACT_APP_EXPERT_URL}`
    const documentuploads=[];
    if(currentDossier!==null)
    {
        const formdata = JSON.parse(currentDossier.formdata);
        if(currentDossier.formtype==="sarl-eurl" || currentDossier.formtype==="SAS/SASU")
        {   
            createurl = currentDossier.formtype==="sarl-eurl"?`${process.env.REACT_APP_EXPERT_URL}/creation/sarl-eurl?formId=${currentDossier.id}`:`${process.env.REACT_APP_EXPERT_URL}/creation/sas-sasu?formId=${currentDossier.id}`;

            if(currentDossier.formtype==="SAS/SASU")
            {
              documentuploads.push(documentSets.liste_des_souscripteurs)
              documentuploads.push(documentSets.certificate_deposit_of_funds);
            }
            if(currentDossier.formtype==="sarl-eurl")
            {
              documentuploads.push(documentSets.certificate_deposit_of_funds_sarl);
            }
              
            documentuploads.push(documentSets.statutes);
            
            documentuploads.push(documentSets.identity_document_legal_representative);
            documentuploads.push(documentSets.declaration_no_conviction_legal_representative);
            documentuploads.push(documentSets.vital_card_legal_representative);
            if(formdata.associates.companyLocation=='legal_representative')
            {
                documentuploads.push(documentSets.authorization_setup_legal_representative);
                documentuploads.push(documentSets.proof_address_legal_representative);
            }
            if(formdata.associates.companyLocation=='domiciliation_center')
            {
                documentuploads.push(documentSets.domiciliation_contract);
            }
            if(formdata.associates.companyLocation=='other_premises')
            {
                documentuploads.push(documentSets.bail_commercial);
            }
            if(formdata.id=='standard')
            {
                documentuploads.push(documentSets.legal_notice_incorporation);
            }
            if(formdata.associates.locationGerance=='oui')
            {
                documentuploads.push(documentSets.legal_notice_rental_management);
            }
            if(formdata.associates.achatFondsCommerce=='oui')
            {
                documentuploads.push(documentSets.legal_notice_purchase_of_business_assets);
            }
            if(formdata.associates.autresRepresentants=='oui')
            {
                documentuploads.push(documentSets.identity_document_of_other_legal_representatives);
                documentuploads.push(documentSets.declaration_no_conviction_other_legal_representative);
            }
        }
        if(currentDossier.formtype==="sci")
        {   
            createurl = `${process.env.REACT_APP_EXPERT_URL}/creation/sci?formId=${currentDossier.id}`;

            documentuploads.push(documentSets.statutes);
            documentuploads.push(documentSets.certificate_deposit_of_funds);
            documentuploads.push(documentSets.identity_document_legal_representative);
            documentuploads.push(documentSets.declaration_no_conviction_legal_representative);
            documentuploads.push(documentSets.piece_identite_du_second_representant_legal_ou_associe);
            documentuploads.push(documentSets.declaration_non_condamnation_second_representant_legal);

            if(formdata.headquarters.location=='manager_home')
            {
                documentuploads.push(documentSets.authorization_setup_legal_representative);
                documentuploads.push(documentSets.proof_address_legal_representative);
            }
            if(formdata.headquarters.location=='domiciliation_center')
            {
                documentuploads.push(documentSets.domiciliation_contract);
            }
            if(formdata.headquarters.location=='other')
            {
                documentuploads.push(documentSets.bail_commercial);
            }
            if(formdata.id=='standard')
            {
                documentuploads.push(documentSets.legal_notice_incorporation);
            }

            if(formdata.headquarters.managerCount>1)
            {
                documentuploads.push(documentSets.piece_identite_cogérant);
                documentuploads.push(documentSets.declaration_non_condamnation_cogerant);
            }
            if(formdata.headquarters.associateCount>2)
            {
                documentuploads.push(documentSets.piece_identite_cogérant);
                documentuploads.push(documentSets.declaration_non_condamnation_cogerant);
            }
            if(formdata.headquarters.hasCompanyAssociates=='oui')
            {
                documentuploads.push(documentSets.piece_identite_cogérant);
                documentuploads.push(documentSets.declaration_non_condamnation_cogerant);
                documentuploads.push(documentSets.kbis_moins_de_3_mois);
            }
        }

        if(currentDossier.formtype==="dissolution")
        {   
            createurl = `${process.env.REACT_APP_EXPERT_URL}/dissolution?formId=${currentDossier.id}`;
            
            if(formdata.timeline=='dissolutiononly' || currentDossier.timeline==="dissolutionandliquidation")
            {
                documentuploads.push(documentSets.pv_de_dissolution);
                documentuploads.push(documentSets.pouvoir);
                if(formdata.liquidator=='president')
                {
                    documentuploads.push(documentSets.pi_du_president);
                }
                if(formdata.liquidator=='associate')
                {
                    documentuploads.push(documentSets.pi_associe);
                    documentuploads.push(documentSets.dnc_associe);
                }  
                if(formdata.liquidator=='third_party')
                {
                    documentuploads.push(documentSets.pi_du_tiers);
                    documentuploads.push(documentSets.dnc_du_tiers);
                    documentuploads.push(documentSets.justificatif_domicile_tiers);
                }
                if(formdata.liquidationHeadquarters.location=='liquidator_address')
                {
                    documentuploads.push(documentSets.justificatif_adresse_liquidateur);
                }
            }

            if(formdata.timeline=='liquidationonly' || currentDossier.timeline==="dissolutionandliquidation")
            {
                documentuploads.push(documentSets.pv_de_liquidation);
                documentuploads.push(documentSets.bilan_de_cloture);
                documentuploads.push(documentSets.attestation_urssaf);
                documentuploads.push(documentSets.attestation_impot);
                documentuploads.push(documentSets.pouvoir);

                if(formdata.liquidator=='president')
                {
                    documentuploads.push(documentSets.pi_du_president);
                }
                if(formdata.liquidator=='associate')
                {
                    documentuploads.push(documentSets.pi_associe);
                    documentuploads.push(documentSets.dnc_associe);
                }
                if(formdata.liquidator=='third_party')
                {
                    documentuploads.push(documentSets.pi_du_tiers);
                    documentuploads.push(documentSets.dnc_du_tiers);
                    documentuploads.push(documentSets.justificatif_domicile_tiers);
                }
            }
        }
        if(currentDossier.formtype==="formalities")
        {   
            createurl = `${process.env.REACT_APP_EXPERT_URL}/approbation-des-comptes?formId=${currentDossier.id}`;
           
            if(formdata.exercise.confidentiality=='yes')
            {
                documentuploads.push(documentSets.pv_agoa);
                documentuploads.push(documentSets.propositions_affectation_resultat);
                documentuploads.push(documentSets.bilan);
                documentuploads.push(documentSets.compte_de_resultat);
                documentuploads.push(documentSets.declaration_confidentialité);
                documentuploads.push(documentSets.autre_document);
                documentuploads.push(documentSets.pouvoir);
            }
            else
            {
                documentuploads.push(documentSets.pv_agoa);
                documentuploads.push(documentSets.propositions_affectation_resultat);
                documentuploads.push(documentSets.bilan);
                documentuploads.push(documentSets.compte_de_resultat);
                documentuploads.push(documentSets.autre_document);
                documentuploads.push(documentSets.pouvoir);
            }
        }

        if(currentDossier.formtype==="modification")
        {   
            createurl = `${process.env.REACT_APP_EXPERT_URL}/modification?formId=${currentDossier.id}`;
                documentuploads.push(documentSets.pv);
                documentuploads.push(documentSets.statuts_maj);
                documentuploads.push(documentSets.piece_identite_representant_legal_actuel);
                documentuploads.push(documentSets.pouvoir);
                documentuploads.push(documentSets.autre_document1);
                documentuploads.push(documentSets.autre_document2);

                if(formdata.id=='standard')
                {
                    documentuploads.push(documentSets.annonce_legale);
                    if(formdata.questions.hasHeadquartersTransfer=='OUI')
                    {
                        documentuploads.push(documentSets.annonce_legale_depart);
                        documentuploads.push(documentSets.annonce_legale_arrivee);
                    }
                }

                if(formdata.modifications.types.registeredOffice==true)
                {
                    documentuploads.push(documentSets.justificatif_nouveau_siege);
                    if(formdata.questions.hasHeadquartersTransfer=='OUI')
                    {
                        documentuploads.push(documentSets.liste_des_sieges_anterieurs);
                    }
                }
                if(formdata.modifications.types.management==true)
                {
                    documentuploads.push(documentSets.piece_identite_nouveau_dirigeant);
                    documentuploads.push(documentSets.declaration_non_condamnation_nouveau_dirigeant);
                }

                if(formdata.modifications.types.showMore==true)
                {
                    documentuploads.push(documentSets.autre_document3);
                    documentuploads.push(documentSets.autre_document4);
                    documentuploads.push(documentSets.autre_document5);
                }
        }

    }
    const handleDownload= (url,name) => {
     // console.log('documentuploads',name);
        // Path to the PDF file (in public folder or hosted online)
        const pdfUrl = url;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = name+".pdf"; // File name for the download
        link.click();
      };
  return (
    <>
    {
      loading?<PageLoaderComp />:""
    }
    <Layout>
      <div className="mb-8 col-span-12 xl:col-span-9">
        <div className="xl:max-w-[1126px] w-full h-full bg-white md:rounded-[20px]">
          {/* Header */}
          <div className="mainHead min-h-[80px] sm:min-h-[120px] flex sm:flex-row flex-col sm:items-center p-3 sm:p-10 sm:justify-between">
            <div>
              <h1 className="text-lg sm:text-xl md:text-lg xl:text-xl 2xl:text-xl font-extrabold text-white sm:mt-0 mt-4">

                Compléter les formalités
              </h1>
            </div>
          </div>

          <div className="p-6">
            {/* Navigation Bar */}
            <div className="flex items-center justify-between px-4 py-3 bg-white border-b">
              <div className="flex items-center space-x-4">
                <Link to="/prouser/mes-dossiers" className="flex items-center text-blue-600 hover:text-blue-700">
                  <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  Mon espace
                </Link>
              </div>
              <div className="flex items-center space-x-6">
                <button 
                  onClick={() => setIsDocumentPopupOpen(true)} 
                  className="flex items-center text-gray-900 hover:text-gray-700"
                >
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                  Documents
                </button>
              </div>
            </div>

            {/* Main Content */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              {/* Dossier Header */}
              <div className="mb-6">
                <div className="flex justify-between items-center">
                  <h2 className="text-xl font-bold text-[#1a237e]">
                    {currentDossier ? currentDossier.title || 'Dossier' : 'Approbation des comptes / AGOA · GC INVESTS'}
                  </h2>
                  <span className="text-gray-500">
                    dossier N°{currentDossier ? currentDossier.id : ''}
                  </span>
                </div>
              </div>

              {/* Stepper */}
              <div className="flex justify-between items-center mb-8">
                {steps.map((step, index) => (
                  <div key={step.number} className="flex items-center">
                    <div className={`flex flex-col items-center ${index !== steps.length - 1 ? 'w-full' : ''}`}>
                      <div className={`relative flex items-center justify-center w-10 h-10 rounded-full border-2 
                        ${step.number === 2 
                          ? 'bg-[#fef3c7] border-[#fbbf24] text-[#92400e]' 
                          : step.number < 2
                            ? 'bg-[#bbf7d0] border-[#22c55e] text-[#166534]'
                            : 'bg-gray-100 border-gray-300 text-gray-500'
                        }`}>
                        <span className="text-sm font-medium">{step.number}</span>
                      </div>
                      <span className="mt-2 text-sm font-medium text-gray-600">{step.label}</span>
                    </div>
                    {index !== steps.length - 1 && (
                      <div className={`w-full h-[2px] mx-4 ${
                        step.number < 2 ? 'bg-[#22c55e]' : 'bg-gray-200'
                      }`} />
                    )}
                  </div>
                ))}
              </div>

              {/* Content Area */}
              <div className="mt-8">
                <h3 className="text-xl font-bold text-center text-[#1a237e] mb-4">
                Votre dossier a été soumis avec succès
                </h3>
                <p className="text-center text-gray-600">
                Notre équipe va procéder à l’analyse de votre formalité. Vous serez informé dès que cette étape sera complétée.
                </p>
              </div>

              {/* Document List */}
              <DocumentList documents={documentuploads} currentDossier={currentDossier} createurl={createurl} />
            </div>

            {/* Document Popup */}
            {isDocumentPopupOpen && (
              <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-medium text-gray-900">Mes Documents</h3>
                        <button
                          onClick={() => setIsDocumentPopupOpen(false)}
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                      <div className="space-y-4">
                        {documentuploads.map((doc, index) => (
                          <div key={index} className="border rounded-lg p-4 hover:bg-gray-50 transition-colors">
                            <div className="flex items-center justify-between">
                              <div className="flex-1">
                                <h3 className="text-lg font-medium text-gray-900">{doc.title}</h3>
                                <p className="text-sm text-gray-500 mt-1">
                                  Dernière mise à jour: {doc.lastUpdated}
                                </p>
                                <p className="text-sm text-gray-600 mt-2">{doc.comments}</p>
                              </div>
                              <div className="flex items-center space-x-3">
                                {getStatusIcon(doc.status)}
                                <span className={`text-sm font-medium ${getStatusColor(doc.status)}`}>
                                  {getStatusText(doc.status)}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
    </>
  );
};

export default Completeformalites;
