import React, { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "./Layout";
import PageLoaderComp from "../component/pageLoaderComp";
import axios from "axios";
import moment from "moment";
import ShareDocumentModal from "../component/modals/shareDocumentModal";

const EsignDesDocuments = () => {
  const [dossiers, setDossiers] = useState([]);
  const [shareItem, setShareItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const currentUser = useSelector((state) => state.authentication.user);
  useEffect(() => {
    fetchDossiers();
  }, [currentUser]);
  const fetchDossiers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/prouser/esign-document/${
          currentUser && currentUser.data ? currentUser.data.id : ""
        }`
      );

      if (response.status === 200 && response.data.success) {
        setDossiers(response.data.data);
      }
    } catch (err) {
      setError("Error fetching dossiers");
    } finally {
      setLoading(false);
    }
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const handleView = (item) => {
    window.open(
      `${process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE}assets/uploads/${item.file_detail}`,
      "_blank"
    );
  };
  const handleDelete = (item) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}api/prouser/esign-document/${item.id}`
      )
      .then((response) => {
        console.log("Item deleted successfully", response.data);
        fetchDossiers();
        // Perform additional actions if necessary, e.g., update UI or state
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // Handle errors here if necessary
      });
  };

  const handleShare = (item) => {
    setIsModalOpen(true);
    setShareItem(item);
  };
  return (
    <>
      {loading ? <PageLoaderComp /> : ""}
      <Layout>
        <div className="mb-8 col-span-12 xl:col-span-9">
          <div className="xl:max-w-[1126px] w-full h-full bg-white md:rounded-[20px]">
            <div className="mainHead min-h-[80px] sm:min-h-[120px] flex flex-row  sm:items-center p-3 sm:p-10 sm:justify-between">
              <div className="w-full">
                <h1 className="text-lg sm:text-xl md:text-lg xl:text-xl 2xl:text-xl font-extrabold text-white sm:mt-0 mt-4">
                  Mes Dossiers
                </h1>
              </div>
              {/* {error && <p className="text-red-500">{error}</p>} */}
              <div className=" w-full  flex justify-end">
                <Link to="/prouser/sigmer-des-documents" className="text-white">
                  <span className="text-base sm:text-xl">+</span>
                  <button className="bg-blue-500 text-white py-2 px-4 rounded">
                    Nouveau documents
                  </button>
                </Link>
              </div>
            </div>

            <div className="p-6 hidden md:block">
            {error && <p className="text-[#f5424e]">{error}</p>}
              <div className="bg-white rounded-lg">
                {/* Table Header */}
                <div className="grid grid-cols-4 gap-4 border-b pb-4 font-medium text-gray-500">
                  <div>Dossier</div>
                  <div>Créé le</div>
                  <div>Statut</div>
                  <div>Action</div>
                </div>

                {/* Table Content */}
                <div className="divide-y">
                  {dossiers.map((item, index) => {
                    return (
                      <div key={index} className="grid grid-cols-4 gap-4 py-4">
                        <div className="text-gray-700">
                          {item.file_detail || "-"}
                        </div>
                        <div className="text-gray-700">
                          {moment(item.created_at).format("DD/MM/YYYY")}
                        </div>
                        <div className="text-gray-700">
                          {item.status === 0 ? "En attente" : "Signé"}
                        </div>
                        <div className="text-gray-700 flex gap-2 flex-col">
                          <button
                            className="bg-primary hover:bg-primary text-white  sm:px-4 py-2 rounded-lg flex items-center justify-center"
                            onClick={() => handleView(item)}
                          >
                            Voir
                          </button>
                          <button
                            className="bg-secondary hover:bg-secondary text-white sm:px-4 py-2 rounded-lg flex items-center justify-center"
                            onClick={() => handleShare(item)}
                          >
                            Share
                          </button>
                          <button
                            className="bg-secondary hover:bg-secondary text-white sm:px-4 py-2 rounded-lg flex items-center justify-center"
                            onClick={() => handleDelete(item)}
                          >
                            Supprimer
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* mobile view  */}

            <div className="mt-8 px-1 block md:hidden ">
              <div className="bg-white rounded-lg">
                {/* Table Header */}
                {/* <div className="grid grid-cols-5 gap-4 border-b pb-4 font-medium text-gray-500">
                  <div>Dossier</div>
                  <div>Entreprise</div>
                  <div>Créé le</div>
                  <div>Statut</div>
                  <div>Action</div>
                </div> */}

                {/* Table Content */}
                <div className="divide-y">
                  {dossiers.map((item, index) => {
                    return (
                      <div key={index} className="mt-3">
                        <div
                          className="flex  flex-col justify-center gap-5 items-center bg-white rounded-[20px] shadow-md  p-3 mt-4"
                          style={{ border: "1px solid #d9d9d9" }}
                        >
                          <div
                            className="flex flex-row justify-start gap-5 items-start w-full"
                            style={{ gap: "15px" }}
                          >
                            <div
                              className="font-medium text-gray-500"
                              style={{ minWidth: "100px" }}
                            >
                              Dossier
                            </div>
                            <div>{item.formtype || "-"}</div>
                          </div>
                          <div
                            className="flex flex-row justify-start gap-5 items-start w-full"
                            style={{ gap: "15px" }}
                          >
                            <div
                              className="font-medium text-gray-500"
                              style={{ minWidth: "100px" }}
                            >
                              Entreprise
                            </div>
                            <div>{item.entreprisename || "-"}</div>
                          </div>
                          <div
                            className="flex flex-row justify-start gap-5 items-start w-full"
                            style={{ gap: "15px" }}
                          >
                            <div
                              className="font-medium text-gray-500"
                              style={{ minWidth: "100px" }}
                            >
                              Créé le
                            </div>
                            <div>
                              {moment(item.created_at).format("DD/MM/YYYY")}
                            </div>
                          </div>

                          <div
                            className="flex flex-row justify-start gap-5 items-start w-full"
                            style={{ gap: "15px" }}
                          >
                            <div
                              className="font-medium text-gray-500"
                              style={{ minWidth: "100px" }}
                            >
                              Action
                            </div>
                            <div className="flex gap-2 flex-wrap">
                              <button
                                className="bg-primary hover:bg-primary text-white w-[5rem] text-center  p-2 rounded-lg flex items-center justify-center"
                                onClick={() => handleView(item)}
                              >
                                Voir
                              </button>
                              <button
                                className="bg-secondary hover:bg-secondary text-white w-[5rem] text-center  p-2 rounded-lg flex items-center justify-center"
                                onClick={() => handleShare(item)}
                              >
                                Share
                              </button>
                              <button
                                className="bg-secondary hover:bg-secondary text-white w-[10.5rem] text-center  p-2 rounded-lg flex items-center justify-center"
                                onClick={() => handleDelete(item)}
                              >
                                Supprimer
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      {/* <EsignModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="w-full max-w-md">
          <h2 className="text-center text-xl font-semibold text-gray-800 mb-2">
            Invitez autant de personnes que vous le souhaitez.
          </h2>
          <p className="text-center text-gray-600 text-sm mb-6">
            Les personnes que vous invitez recevront un lien pour consulter
            votre document, mais elles ne pourront pas le modifier ni le signer.
          </p>

          <form className="space-y-5">
            <div>
              <label htmlFor="name" className="block text-gray-800 font-medium">
                Nom
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none bg-gray-50"
                placeholder="Entrez le nom"
                required
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-gray-800 font-medium"
              >
                E-mail
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none bg-gray-50"
                placeholder="Entrez l'adresse e-mail"
                required
              />
            </div>

            <div>
              <label
                htmlFor="message"
                className="block text-gray-800 font-medium"
              >
                Message (facultatif)
              </label>
              <textarea
                name="message"
                id="message"
                rows="4"
                maxLength="600"
                value={formData.message}
                onChange={handleChange}
                className="mt-1 w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none bg-gray-50 resize-none"
                placeholder="Merci d'avance pour l'examen de mon document !"
              ></textarea>
              <div className="text-right text-xs text-gray-400">
                {formData.message.length}/600
              </div>
            </div>
            <div className="mt-4 flex gap-4 justify-end">
              <button
                className="bg-[#979797] text-white px-6 py-2 rounded hover:bg-[#979797]"
                onClick={() => setIsModalOpen(false)}
              >
                Annuler
              </button>
              <button
                onClick={handleSubmit}
                className={`bg-secondary text-white px-6 py-2 rounded hover:bg-primary ${formData.name ==="" || formData.email ==="" ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={formData.name ==="" || formData.email ===""}
              >
                Envoyer l'invitation
              </button>
            </div>
          </form>
        </div>
      </EsignModal> */}
      <ShareDocumentModal
        isModalOpen={isModalOpen}
        setIsModalOpen={(e) => setIsModalOpen(e)}
        fetchDossiers={fetchDossiers}
        shareItem={shareItem}
      />
    </>
  );
};
export default EsignDesDocuments;
