import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
// import { history } from '../_helpers';

export const userActions = {
    login,
    userSession,
    logout,
    register,
    getAll,
    update,
    addAddress,
    forgot_password,
    delete: _delete
};

function forgot_password(email) {
    return dispatch => {
        dispatch(request({ email }));
        return userService.forgot_password(email)
            .then(
                user => {
                    // console.log(user)
                    dispatch(success(user));
                    // history.push(from);  
                    dispatch(alertActions.success(user.msg));
                    /* if(user.sucess === true){
                        dispatch(alertActions.success(user.msg)); 
                    }else{
                        dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!"));  
                    } */
                    return Promise.resolve();
                },
                error => {
                    console.log(error)
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    return Promise.reject();
                }
            );
    };
    function request(user) { return { type: userConstants.FORGOTPASSWORD_REQUEST, user } }
    function success(user) { return { type: userConstants.FORGOTPASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.FORGOTPASSWORD_FAILURE, error } }
}

function login(userdata) {
    return dispatch => {
        dispatch(request(userdata.email));
        // console.log(userdata)
        return userService.login(userdata)
            .then(
                user => {
                    // console.log(user)
                    if (user.sucess) {
                        dispatch(success(user));
                        dispatch(alertActions.success(user.msg));
                        return Promise.resolve(user);
                    } else {
                        dispatch(failure(user.message || user.msg));
                        dispatch(alertActions.error(user.message || user.msg));
                        return Promise.reject();
                    }
                },
                error => {
                    console.log(error)
                    dispatch(failure(error.toString() || "Failed To Login"));
                    dispatch(alertActions.error(error.toString() || "Failed To Login"));
                    return Promise.reject();
                }
            );
    };

    function request(email) 
    {
        return { type: userConstants.LOGIN_REQUEST, email };
    }

    function success(user) 
    {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }

    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
}

function userSession(userdata) {
    return dispatch => {
        dispatch(request(userdata.token));

        return userService.userSession(userdata)
            .then(
                user => {
                    if (user?.sucess === true || user?.success === true) {
                        // First dispatch login success to update header state
                        dispatch({ type: userConstants.LOGIN_SUCCESS, user });
                        // Then dispatch session success
                        dispatch(success(user));
                        dispatch(alertActions.success(user.msg));
                        return Promise.resolve(user);
                    } else {
                        dispatch(failure(user.message || user.msg));
                        dispatch(alertActions.error(user.message || user.msg));
                        return Promise.reject();
                    }
                },
                error => {
                    console.log(error);
                    dispatch(failure(error.toString() || "Failed To Login"));
                    dispatch(alertActions.error(error.toString() || "Failed To Login"));
                    return Promise.reject();
                }
            );
    };

    function request(token) {
        return { type: userConstants.SESSION_REQUEST, token };
    }

    function success(user) {
        return { type: userConstants.SESSION_SUCCESS, user };
    }

    function failure(error) {
        return { type: userConstants.SESSION_FAILURE, error };
    }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function addAddress(formData) {
    return dispatch => {
        dispatch(request(formData));
        return userService.addAddress(formData)
            .then(
                user_address => {
                    if (user_address.sucess === true) {
                        dispatch(success(user_address.user_address));
                        dispatch(alertActions.success(user_address.msg));
                    } else {
                        dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!"));
                    }
                    return Promise.resolve(user_address);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    return Promise.reject();
                }
            );
    };
    function request(user_address) { return { type: userConstants.ADDRESS_REQUEST, user_address } }
    function success(user_address) { return { type: userConstants.ADDRESS_SUCCESS, user_address } }
    function failure(error) { return { type: userConstants.ADDRESS_FAILURE, error } }
}

function register(user) {
    if (user.signup_type === 'google' || user.signup_type === 'facebook') {
        return dispatch => {
            dispatch(requestSocial(user));
            return userService.register(user)
                .then(
                    user => {
                        if (user.sucess === true) {
                            dispatch(successSocial(user));
                            dispatch(alertActions.success(user.msg));
                            return Promise.resolve(user);
                        } else {
                            dispatch(failureSocial("Une erreur s'est produite. Veuillez réessayer!"));
                            dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!"));
                            return Promise.reject();
                        }
                    },
                    error => {

                        dispatch(failureSocial(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                        return Promise.reject();
                    }
                );
        };
    } else {
        return dispatch => {
            dispatch(request(user));
            return userService.register(user)
                .then(
                    user => {
                        if (user.sucess === true) {
                            dispatch(success(user));
                            dispatch(alertActions.success(user.msg));
                            return Promise.resolve(user);
                        } else {
                            dispatch(failure("Une erreur s'est produite. Veuillez réessayer!"));
                            dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!"));
                            return Promise.reject();
                        }
                    },
                    error => {

                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                        return Promise.reject();
                    }
                );
        };

    }

    function requestSocial(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function successSocial(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failureSocial(error) { return { type: userConstants.LOGIN_FAILURE, error } }

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function update(user) {
    return dispatch => {
        dispatch(request(user));

        return userService.update(user)
            .then(
                user => {
                    if (user.sucess === true) {
                        dispatch(success(user));
                        dispatch(alertActions.success(user.msg));
                        return Promise.resolve();
                    } else {
                        dispatch(failure("Une erreur s'est produite. Veuillez réessayer!"));
                        dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!"));
                        return Promise.reject();
                    }

                },
                error => {

                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    return Promise.reject();
                }
            );
    };
    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function getAll() {
    return async dispatch => {
        dispatch(request());

        try {
            const users = await userService.getAll();
            dispatch(success(users));
            return users; // Return the data
        } catch (error) {
            dispatch(failure(error.toString()));
            throw error;
        }
    };

    function request() {
        return { type: userConstants.GETALL_REQUEST };
    }

    function success(users) {
        console.log(users)
        return { type: userConstants.GETALL_SUCCESS, users };
    }

    function failure(error) {
        return { type: userConstants.GETALL_FAILURE, error };
    }
}


// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}
