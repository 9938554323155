import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Header from "../component/Header";
import NoPage from "../pages/NoPage";
import AuthVerify from "../pages/AuthVerify";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import ConditionsGeneralesVente from "../pages/ConditionsGeneralesVente";
import Faq from "../pages/Faq";
import MentionsLegales from "../pages/MentionsLegales";
import Dashboard from "../Dashboard/Dashboard";

import AnnoncesPubliees from "../Dashboard/AnnoncesPubliees";
import AnnoncesEnAttente from "../Dashboard/AnnoncesEnAttente";
import Factures from "../Dashboard/Factures";
import AnnonceLegale from "../Dashboard/AnnonceLegale";
import Profile from "../Dashboard/Profile";
import InvoiceTemplate from "../component/pdf/InvoiceTemplate";
import Protected from "./Protected";
import { CheckLoggedin } from "./Protected";
import RegistrationForm from "../pages/pro/RegistrationForm";
import ProPresentation from "../pages/pro/ProPresentation";
import ProAccountingActivity from "../pages/pro/ProAccountingActivity";
import OpeningHours from "../pages/pro/OpeningHours";
import ProAccess from "../pages/pro/ProAccess";
import Profil from "../pages/pro/Profil";
import ProLogo from "../pages/pro/ProLogo";
import ProfessionalLanguage from "../pages/pro/ProfessionalLanguage";
import PartagezVotreProfil from "../pages/pro/PartagezVotreProfil";
import PersonalInformation from "../pages/pro/PersonalInformation";
import Registration from "../pages/Registration";
import Agenda from "../Dashboard/Agenda";
import MesDossiers from "../Dashboard/mes-dossiers";
import Completeformalites from "../Dashboard/complete-formalities";
import FormaliesStatus from "../Dashboard/Formalies-Status";
import SigmerDesDocuments from "../Dashboard/sigmer-des-documents";
import EsignDesDocuments from "../Dashboard/esign-des-documents";
import SignaturePad from "../Dashboard/SignaturePad";
import Messagerie from "../pages/pro/Messagerie";
import Devis from "../Dashboard/Devis";
import Parametresentreprise from "../Dashboard/Parametresentreprise";
import Login from "../pages/Login";
import NosFormulairesMain from "../pages/NosFormulairesMain";
import JournauxHabilites from "../pages/Form/JournauxHabilites";
import AnnuaireDesNotaires from "../pages/Directory/AnnuaireDesNotaires";
import GreffesDeFrance from "../pages/Directory/GreffesDeFrance";
import ExpertsComptables from "../pages/Directory/ExpertsComptables";
import AnnuaireDesAvocats from "../pages/Directory/AnnuaireDesAvocats";
import StepperForm from "../pages/Form/StepperForm";
import FicheExpertsComptable from "../pages/FicheExpertsComptable";
import DirectorySearchResults from "../pages/DirectorySearchResults";
import FicheAvocats from "../pages/Directory/FicheAvocats";
import FicheNotaires from "../pages/Directory/FicheNotaires";
import RechercheDesAnnocesLegales from "../pages/RechercheDesAnnocesLegales"
import RechercheDesAnnocesLegalesDetails from "../pages/RechercheDesAnnocesLegalesDetails"
import Hero from "../pages/HomePage/Hero";
import DirectoryOfChartered from "../pages/DirectoryOfChartered";
import DirectoryOfAvocats from "../pages/DirectoryOfAvocats";
import DirectoryOfNotaires from "../pages/DirectoryOfNotaires";
import DirectoryOfGreffe from "../pages/DirectoryOfGreffe";
import FicheGreffe from "../pages/FicheGreffe";
import ThankYouPage from "../pages/Form/ThankYouPage";
import FormulaireInscriptionPro from "../pages/Form/FormulaireInscriptionPro";
import GreffeDeFranceSélectionGreffe from "../pages/Form/GreffeDeFranceSélectionGreffe";
import EsignerDesDocuments from "../pages/esignShare";

function WebRoutes() {

  return (
    <Routes>
      <Route path="*" element={<Navigate to='/' />} />
      <Route path="/pdfView" element={<InvoiceTemplate />} />
      <Route path="/" element={<Header />}>
        {/* <Route path="/" element={<Login />}> */}
        {/* <Route path="/home" element={<Home />} /> */}
        <Route index element={<CheckLoggedin><Home /></CheckLoggedin>} />
        {/* login page routes */}

        <Route path="/login" element={<CheckLoggedin><Login /></CheckLoggedin>} />

        <Route path="Registration" element={<CheckLoggedin><Registration /></CheckLoggedin>} />
        <Route path="/auth/verify/:email/:token" element={<AuthVerify />} />
        <Route path="/forgot-password" element={<CheckLoggedin><ForgotPassword /></CheckLoggedin>} />
        <Route
          path="/resetpassword/:email/:token"
          element={<ResetPassword />}
        />

        <Route path="/nos-formulaires" element={<NosFormulairesMain />} />
        <Route path="/nos-formulairess" element={<Hero/>} />
        <Route path="/thank-you" element={<ThankYouPage />} />

        <Route
          path="/nos-formulaires/:ads/:adsname"
          element={<StepperForm />}
        />
        <Route
          path="/nos-formulaires/:ads/:adsname/:id"
          element={<StepperForm />}
        />
        <Route path="/journaux-habilites" element={<JournauxHabilites />} />
        <Route path="/notaires" element={<AnnuaireDesNotaires />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route
          path="/conditions-generales-vente"
          element={<ConditionsGeneralesVente />}
        />
        <Route path="/faq" element={<Faq />} />
        <Route path="/greffes-de-France" element={<GreffesDeFrance />} />
        <Route path="/experts-comptables" element={<ExpertsComptables />} />
        <Route path="/avocats" element={<AnnuaireDesAvocats />} />
        <Route path="/esign-share/:id/:token" element={<EsignerDesDocuments />} />

        {/* =========================|Pro pages|============================ */}

        <Route path="/personalInformation" element={<CheckLoggedin><PersonalInformation /></CheckLoggedin>} />
        <Route path="/registrationform/:id" element={<CheckLoggedin><RegistrationForm /></CheckLoggedin>} />
        <Route path="/propresentation/:id" element={<CheckLoggedin><ProPresentation /></CheckLoggedin>} />
        <Route path="/proaccountingactivity/:id" element={<CheckLoggedin><ProAccountingActivity /></CheckLoggedin>} />
        <Route path="/professionalanguage/:id" element={<CheckLoggedin><ProfessionalLanguage /></CheckLoggedin>} />
        <Route path="/openinghours/:id" element={<CheckLoggedin><OpeningHours /></CheckLoggedin>} />
        <Route path="/proaccess/:id" element={<CheckLoggedin><ProAccess /></CheckLoggedin>} />
        <Route path="/partagezvotreprofil/:id" element={<CheckLoggedin><PartagezVotreProfil /></CheckLoggedin>} />
        <Route path="/prologo/:id" element={<CheckLoggedin><ProLogo /></CheckLoggedin>} />
        <Route path="/profil" element={<Profil />} />

       

        {/* =========================|Pro pages End|============================ */}


        <Route path="*" element={<NoPage />} />

    {/* others routes */}
    <Route path="/fiche-avocats/:name/:id" element={<FicheAvocats />} />
    <Route path="/fiche-notaires/:name/:id" element={<FicheNotaires />} />
    <Route
      path="/fiche-experts-comptables/:name/:id"
      element={<FicheExpertsComptable />}
    />

    <Route
      path="/directory_search_result/:type/:searchKey/:size/:currentPage"
      element={<DirectorySearchResults />}
    />
      <Route
      path="/recherche-des-annoces-legales/:id"
      element={<RechercheDesAnnocesLegalesDetails />}
    />
    <Route
      path="/recherche-des-annoces-legales"
      element={<RechercheDesAnnocesLegales />}
    />
    <Route
      path="/annuaire-des-experts-comptable-selection/:city/:postalCode"
      element={<DirectoryOfChartered />}
      exact 
    />
    <Route
          path="/annuaire-des-greffe-selection/:city/:postalCode"
          element={<DirectoryOfGreffe />}
        />
         <Route
          path="/annuaire-des-avocats-selection/:city/:postalCode"
          element={<DirectoryOfAvocats />}
        />
        <Route
          path="/annuaire-des-notaires-selection/:city/:postalCode"
          element={<DirectoryOfNotaires />}
        />
        <Route
          path="/fiche-experts-comptables/:name/:id"
          element={<FicheExpertsComptable />}
        />
        <Route
          path="/directory_search_result/:type/:searchKey/:size/:currentPage"
          element={<DirectorySearchResults />}
        />
        <Route
          path="/fiche-greffe/:id"
          element={<FicheGreffe />}
        />
        <Route
          path="/recherche-des-annoces-legales/:id"
          element={<RechercheDesAnnocesLegalesDetails />}
        />
        <Route
          path="/recherche-des-annoces-legales"
          element={<RechercheDesAnnocesLegales />}
        />
        <Route
          path="/greffe-de-France-selection-greffe"
          element={<GreffeDeFranceSélectionGreffe />}
        />

        <Route
          path="/formulaire-inscription-pro/:type/:name/:id"
          element={<FormulaireInscriptionPro />}
        />
    {/* <Route
      path="/annuaire-des-greffe-selection/:city/:postalCode"
      element={<DirectoryOfGreffe />}
    />
      <Route
      path="/annuaire-des-avocats-selection/:city/:postalCode"
      element={<DirectoryOfAvocats />}
    />
    <Route
      path="/annuaire-des-notaires-selection/:city/:postalCode"
      element={<DirectoryOfNotaires />}
    /> */}

        {/* =========================|Admin Routes|============================ */}
        <Route
          path="/dashboard"
          element={
            <Protected>
              <Dashboard />
            </Protected>
          }
        />
        <Route
          path="/prouser/agenda"
          element={
            <Protected>
              <Agenda />
            </Protected>
          }
        />
        <Route
          path="/prouser/messagerie"
          element={
            <Protected>
              <Messagerie />
            </Protected>
          }
        />
        <Route
          path="/prouser/mes-dossiers"
          element={
            <Protected>
              <MesDossiers />
            </Protected>
          }
        />
        <Route
          path="/complete-formalities/:id"
          element={<Completeformalites />}
        />
        <Route
          path="/formalies-status/:id"
          element={<FormaliesStatus />}
        />
        <Route
          path="/prouser/sigmer-des-documents"
          element={
            <Protected>
              <SigmerDesDocuments />
            </Protected>
          }
        />
        <Route
          path="/prouser/esign-des-documents"
          element={
            <Protected>
              <EsignDesDocuments />
            </Protected>
          }
        />
        <Route
          path="/prouser/signaturepad"
          element={
            <Protected>
              <SignaturePad />
            </Protected>
          }
        />
        
        <Route
          path="/prouser/profile"
          element={
            <Protected>
              <Profile />
            </Protected>
          }
        />
        <Route
          path="/prouser/annoncespublies"
          element={
            <Protected>
              <AnnoncesPubliees />
            </Protected>
          }
        />
        <Route
          path="/prouser/annoncesenattente"
          element={
            <Protected>
              <AnnoncesEnAttente />
            </Protected>
          }
        />
        <Route
          path="/prouser/devis"
          element={
            <Protected>
              <Devis />
            </Protected>
          }
        />

        <Route
          path="/prouser/factures"
          element={
            <Protected>
              <Factures />
            </Protected>
          }
        />

        <Route
          path="/prouser/parametresentreprise"
          element={
            <Protected>
              <Parametresentreprise />
            </Protected>
          }
        />

        <Route
          path="/prouser/annoncelegale"
          element={
            <Protected>
              <AnnonceLegale />
            </Protected>
          }
        />
      </Route>


    </Routes>
  );
}

export default WebRoutes;
