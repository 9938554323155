/* eslint-disable array-callback-return */
const fullNameJson=[
    {
        "title":"Constitution de société commerciale",
        "sarl":"Société à Responsabilité Limitée",
        "eurl":"SARL Unipersonnelle",
        "snc":"Société en Nom Collectif",
        "sasu":"Société par Actions Simplifiée Unipersonnelle",
        "sas":"Société par Actions Simplifiée",
        "sa":"Société Anonyme",
    },
    {
        "title":"Constitution d'une société civile",
        "sc":"Société Civile",
        "sci":"Société Civile Immobilière",
        "sccv":"SCI de Construction Vente",
        "scicv":"SCI de Construction Vente",
        "scm":"Société Civile De Moyens",
        "scpi":"Société Civile De Placement Immobilier",
        "scp":"Société Civile Professionnelle",
    },
    {
        "title":"Constitution d'une société Libérale",
        "selarl":"Société d'Exercice Libéral à Responsabilité Limitée ",
        "seleurl":"Société d'Exercice Libéral ayant un associé Unique",
        "selafa":"Société d'Exercice Libéral sous Forme Anonyme ",
        "selas":"Société d'Exercice Libéral par Actions Simplifiée",
        "selasu":"Société d'Exercice Libéral par Actions Simplifiée Unipersonnelle",
    },
    {
        "title":"Modification de société",
        "modifications-multiples":"Modifications multiples",
        "modification-capital-social":"Avis de modification de capital",
        "cession-parts-sociales":"Cession de parts/actions sociales",
        "changement-denomination":"Changement de dénomination",
        "changement-objet-social":"Modification d'objet social",
        "continuation-activite":"Poursuite d'activité malgré pertes",
        "prorogation-duree":"Prorogation de durée",
        "transfert-siege-meme-departement":"Transfert de siège social dans le département",
        "transfert-siege-hors-departement-depart":"Transfert de siège social hors département (départ)",
        "transfert-siege-hors-departement-arrivee":"Transfert de siège social hors département (arrivée)",
        "transformation-sa-en-sas":"Transformation d’une SA en SAS",
        "transformation-sarl-en-sas":"Transformation d’une SARL en SAS",
        "transformation-sas-en-sarl":"Transformation d’une SAS en SARL",
    },
    {
        "title":"Cessation d’activité",
        "dissolution-anticipee":"Avis de dissolution anticipée",
        "cloture-liquidation":"Avis de clôture de liquidation",
        "dissolution-sans-liquidation":"Avis de dissolution sans liquidation TUP",
    },
    {
        "title":"Fonds de Commerce",
        "apport-fond-commerce":"Apport de Fonds de Commerce",
        "cession-droit-bail":"Cession de Droit au Bail",
        "fin-location-gerance":"Fin de location gérance",
        "location-gerance":"Location gérance",
        "cession-fond-commerce":"Vente de Fonds de Commerce",
    },
    {
        "title":"Additifs - Rectificatifs",
        "additif":"Additif",
        "rectificatif":"Annonce rectificative",
    }
];

export const Ads_fullname =(adsname)=>{ 
    var adsnameGet = ""; 
    let filterData = fullNameJson.filter((val,key)=>Object.keys(val).includes(adsname));
    if(filterData.length>0){
        adsnameGet=filterData[0][adsname];
    }
    return adsnameGet;
}

export const Ads_redirect =(adsname)=>{ 
    switch (adsname) 
    {
        case "sarl":
        case "eurl":
            return 'creation/sarl-eurl';
        case "sasu":
        case "sas":
            return 'creation/sas-sasu';
        case "sci":
        case "sccv":
            return 'creation/sci';
        case "snc":
        case "sa":
        case "sc":
        case "scm":
        case "scpi":
        case "scp":
        case "selarl" :   
        case "seleurl":
        case "selafa":
        case "selas":
        case "selasu":
          return 'creation';
        case "modifications-multiples":
        case "changement-gerant":
        case "modification-capital-social":
        case "cession-parts-sociales":
        case "changement-denomination":
        case "changement-objet-social":
        case "continuation-activite":
        case "prorogation-duree":
        case "transfert-siege-meme-departement":
        case "transfert-siege-hors-departement-depart":
        case "transfert-siege-hors-departement-arrivee":
        case "transformation-sa-en-sas":
        case "transformation-sarl-en-sas":
        case "transformation-sas-en-sarl":
          return 'modification';
        case "dissolution-anticipee":
        case "cloture-liquidation":
        case "dissolution-sans-liquidation":
          return 'dissolution' ;
        default:
          return '';
      }
}