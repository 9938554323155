import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "./Layout";
import PageLoaderComp from "../component/pageLoaderComp";
import axios from "axios";
import moment from "moment";

const Completeformalites = () => {
  const [dossiers, setDossiers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const currentUser = useSelector((state) => state.authentication.user);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState("");

  useEffect(() => {
    const fetchDossiers = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/prouser/dossiers_all/${
            currentUser && currentUser.data ? currentUser.data.id : ""
          }`
        );

        if (response.status === 200 && response.data.success) {
          setDossiers(response.data.data);
        }
      } catch (err) {
        setError("Error fetching dossiers");
      } finally {
        setLoading(false);
      }
    };
    fetchDossiers();
  }, [currentUser]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  const handleLinkClick = (e, url) => {
    e.preventDefault();
    setSelectedUrl(url);
    setShowConfirmModal(true);
  };

  const handleConfirm = () => {
    window.open(selectedUrl, "_blank");
    setShowConfirmModal(false);
  };
  return (
    <>
      {loading ? <PageLoaderComp /> : ""}
      <Layout>
        <div className="mb-8 col-span-12 xl:col-span-9">
          <div className="xl:max-w-[1126px] w-full h-full bg-white md:rounded-[20px]">
            <div className="mainHead min-h-[80px] sm:min-h-[120px] flex flex-row  sm:items-center p-3 sm:p-10 sm:justify-between">
              <div className="w-full">
                <h1 className="text-lg sm:text-xl md:text-lg xl:text-xl 2xl:text-xl font-extrabold text-white sm:mt-0 mt-4">
                  Mes Dossiers
                </h1>
              </div>
              <div className=" w-full  flex justify-end">
                {console.log("currentUser.data.token", currentUser)}
                <a
                  href={`${process.env.REACT_APP_EXPERT_URL}?id=${currentUser.token}`}
                  target="_blank"
                  className="bg-blue-500 hover:bg-blue-600 text-white sm:px-4 py-2 rounded-lg flex items-center gap-2"
                >
                  <span className="text-base sm:text-xl">+</span>
                  <span className="text-base sm:text-xl">Nouveau dossier</span>
                </a>
              </div>
            </div>

            <div className="p-6 hidden md:block">
              <div className="bg-white rounded-lg">
                {/* Table Header */}
                <div className="grid grid-cols-5 gap-4 border-b pb-4 font-medium text-gray-500">
                  <div>Dossier</div>
                  <div>Entreprise</div>
                  <div>Créé le</div>
                  <div>Statut</div>
                  <div>Action</div>
                </div>

                {/* Table Content */}
                <div className="divide-y">
                  {dossiers.map((item, index) => {
                    const formType = item.formtype?.toLowerCase() || "";
                    const displayType = [
                      "sas",
                      "sasu",
                      "srl",
                      "eurl",
                      "sci",
                      "sarl",
                      "sas/sasu",
                      "sarl-eurl",
                    ].includes(formType)
                      ? "Création"
                      : formType === "formalites" || formType === "formalities"
                      ? "Approbation des comptes"
                      : formType === "dissolution"
                      ? "Dissolution"
                      : formType === "modification"
                      ? "Modification"
                      : item.formtype || "-";
                    return (
                      <div key={index} className="grid grid-cols-5 gap-4 py-4">
                        <div className="text-gray-700">{displayType}</div>
                        <div className="text-gray-700">
                          {item.entreprisename || "-"}
                        </div>
                        <div className="text-gray-700">
                          {moment(item.created_at).format("DD/MM/YYYY")}
                        </div>

                        <div
                          style={{
                            padding: "4px 12px",
                            borderRadius: "6px",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            justifyItems: "start",
                            alignItems: "center",
                            backgroundColor:
                              item.status === 0
                                ? "#EF4444" // Red
                                : item.status === 1
                                ? "#EAB308" // Yellow
                                : item.status === 2
                                ? "#3B82F6" // Blue
                                : item.status === 3
                                ? "#22C55E" // Green
                                : "#059669", // Emerald
                          }}
                        >
                          {item.status === 0
                            ? "Documents manquants"
                            : item.status === 1
                            ? "Dossier soumis"
                            : item.status === 2
                            ? "En cours d'analyse"
                            : item.status === 3
                            ? "Dossier validé"
                            : "Dossier finalisé"}
                        </div>
                        <div>
                          {(() => {
                            if (item.status === 0) {
                              const url = `${
                                process.env.REACT_APP_EXPERT_URL
                              }/${
                                item.formtype === "sarl-eurl"
                                  ? "creation/sarl-eurl"
                                  : item.formtype === "SAS/SASU"
                                  ? "creation/sas-sasu"
                                  : item.formtype === "sci"
                                  ? "creation/sci"
                                  : item.formtype === "modification"
                                  ? "modification"
                                  : item.formtype === "dissolution"
                                  ? "dissolution"
                                  : item.formtype === "formalities"
                                  ? "approbation-des-comptes"
                                  : "creation"
                              }?formId=${item.id}`;
                              return (
                                <a
                                  href="#"
                                  onClick={(e) => handleLinkClick(e, url)}
                                  className="text-base font-semibold text-white flex items-center justify-center px-4 py-2 sm:w-auto w-full rounded-md bg-primary"
                                >
                                  Compléter mon dossier
                                </a>
                              );
                            } else if (item.status === 1) {
                              return (
                                <a
                                  href={`/complete-formalities/${item.id}`}
                                  className="text-base font-semibold text-white flex items-center justify-center px-4 py-2 sm:w-auto w-full rounded-md bg-primary"
                                >
                                  Complétez votre dossier
                                </a>
                              );
                            } else if (item.status === 2) {
                              return (
                                <a
                                  href={`/complete-formalities/${item.id}`}
                                  className="text-base font-semibold text-white flex items-center justify-center px-4 py-2 sm:w-auto w-full rounded-md bg-primary"
                                >
                                  En cours de traitement par nos équipes
                                </a>
                              );
                            } else {
                              return <p>Finalisé avec succès</p>;
                            }
                          })()}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* mobile view  */}

            <div className="mt-8 px-1 block md:hidden ">
              <div className="bg-white rounded-lg">
                {/* Table Header */}
                {/* <div className="grid grid-cols-5 gap-4 border-b pb-4 font-medium text-gray-500">
                  <div>Dossier</div>
                  <div>Entreprise</div>
                  <div>Créé le</div>
                  <div>Statut</div>
                  <div>Action</div>
                </div> */}

                {/* Table Content */}
                <div className="divide-y">
                  {dossiers.map((item, index) => {
                    const formType = item.formtype?.toLowerCase() || "";
                    const displayType = [
                      "sas",
                      "sasu",
                      "srl",
                      "eurl",
                      "sci",
                      "sarl",
                      "sas/sasu",
                      "sarl-eurl",
                    ].includes(formType)
                      ? "Création"
                      : formType === "formalites" || formType === "formalities"
                      ? "Approbation des comptes"
                      : formType === "dissolution"
                      ? "Dissolution"
                      : formType === "modification"
                      ? "Modification"
                      : item.formtype || "-";
                    return (
                      <div key={index} className="mt-3">
                        <div
                          className="flex  flex-col justify-center gap-5 items-center bg-white rounded-[20px] shadow-md  p-3 mt-4"
                          style={{ border: "1px solid #d9d9d9" }}
                        >
                          <div
                            className="flex flex-row justify-start gap-5 items-start w-full"
                            style={{ gap: "15px" }}
                          >
                            <div className="font-medium text-gray-500" style={{ minWidth: "100px", }}>Dossier</div>
                            <div>{displayType}</div>
                          </div>
                          <div
                            className="flex flex-row justify-start gap-5 items-start w-full"
                            style={{ gap: "15px" }}
                          >
                            <div className="font-medium text-gray-500" style={{ minWidth: "100px" }}>Entreprise</div>
                            <div>{item.entreprisename || "-"}</div>
                          </div>
                          <div
                            className="flex flex-row justify-start gap-5 items-start w-full"
                            style={{ gap: "15px" }}
                          >
                            <div className="font-medium text-gray-500" style={{ minWidth: "100px" }}>Créé le</div>
                            <div>
                              {moment(item.created_at).format("DD/MM/YYYY")}
                            </div>
                          </div>
                          <div
                            className="flex flex-row justify-start gap-5 items-start w-full"
                            style={{ gap: "15px" }}
                          >
                            <div className="font-medium text-gray-500" style={{ minWidth: "100px" }}>Statut</div>
                            <div
                              className="text-sm sm:text-base  font-semibold text-white flex items-center justify-center  px-2 sm:px-4 py-2 sm:w-auto w-full rounded-md"
                              style={{
                                width: "100%", 
                                color: "white",

                                backgroundColor:
                                  item.status === 0
                                    ? "#EF4444" // Red
                                    : item.status === 1
                                    ? "#EAB308" // Yellow
                                    : item.status === 2
                                    ? "#3B82F6" // Blue
                                    : item.status === 3
                                    ? "#22C55E" // Green
                                    : "#059669", // Emerald
                              }}
                            >
                              {item.status === 0
                                ? "Documents manquants"
                                : item.status === 1
                                ? "Dossier soumis"
                                : item.status === 2
                                ? "En cours d'analyse"
                                : item.status === 3
                                ? "Dossier validé"
                                : "Dossier finalisé"}
                            </div>
                          </div>
                          <div
                            className="flex flex-row justify-start gap-5 items-start w-full"
                            style={{ gap: "15px" }}
                          >
                            <div className="font-medium text-gray-500" style={{ minWidth: "100px" }}>Action</div>
                            <div style={{ width: "100%" }}>
                              {(() => {
                                if (item.status === 0) {
                                  const url = `${
                                    process.env.REACT_APP_EXPERT_URL
                                  }/${
                                    item.formtype === "sarl-eurl"
                                      ? "creation/sarl-eurl"
                                      : item.formtype === "SAS/SASU"
                                      ? "creation/sas-sasu"
                                      : item.formtype === "sci"
                                      ? "creation/sci"
                                      : item.formtype === "modification"
                                      ? "modification"
                                      : item.formtype === "dissolution"
                                      ? "dissolution"
                                      : item.formtype === "formalities"
                                      ? "approbation-des-comptes"
                                      : "creation"
                                  }?formId=${item.id}`;
                                  return (
                                    <a
                                      href="#"
                                      onClick={(e) => handleLinkClick(e, url)}
                                      className="text-sm sm:text-base font-semibold text-white flex items-center justify-center  px-2 sm:px-4 py-2  rounded-md bg-primary"
                                      style={{ width: "100%" }}
                                    >
                                      Compléter mon dossier
                                    </a>
                                  );
                                } else if (item.status === 1) {
                                  return (
                                    <a
                                      href={`/complete-formalities/${item.id}`}
                                      className="text-sm sm:text-base font-semibold text-white flex items-center justify-center text-center px-2 sm:px-4 py-2  rounded-md bg-primary"
                                      style={{ width: "100%" }}
                                    >
                                      Complétez votre dossier
                                      
                                    </a>
                                  );
                                } else if (item.status === 2) {
                                  return (
                                    <a
                                      href={`/complete-formalities/${item.id}`}
                                      className="text-sm sm:text-base font-semibold text-white flex items-center justify-center  px-2 sm:px-4 py-2  rounded-md bg-primary"
                                      style={{ width: "100%" }}
                                    >
                                      En cours de traitement par nos équipes
                                    </a>
                                  );
                                } else {
                                  return <p>Finalisé avec succès</p>;
                                }
                              })()}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      {/* Confirmation Modal */}
      {showConfirmModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Confirmation</h2>
            <p className="text-gray-600 mb-6">
              Êtes-vous sûr de vouloir continuer vers la form de modification ?
            </p>
            <p className="text-gray-600 mb-6">
              Des frais supplémentaires peuvent s'appliquer si les modifications
              affectent les conditions de paiement d'origine.
            </p>

            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowConfirmModal(false)}
                className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
              >
                Annuler
              </button>
              <button
                onClick={handleConfirm}
                class="text-base font-semibold text-white flex items-center justify-center px-4 py-2 sm:w-auto w-full rounded-md bg-primary"
              >
                Continuer
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Completeformalites;
