
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { IoMdCloseCircle } from 'react-icons/io';
import { getResponse, updateResponse } from './APIs';
import ToastNotification from '../ToastContainer/ToastNotification';
import { Autocomplete,MenuItem,Select} from '@mui/material'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom'
import { LogoutApp } from '../../../_helpers';
import { userConstants } from '../../../_constants';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    width: '700px',
    maxWidth: '700px',
    maxHeight: '700px',
  },
}));

const CompanyModal = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const currentUser = useSelector(state => state.authentication.user);
  const [citiesData, setCitiesData] = useState([]);
  const [open, setOpen] = useState(false);
  const [societyData, setSocietyData] = useState({
    user_id: currentUser?.data?.id,
    company_name: '',
    address: '',
    comp_address: '',
    postalcode: '',
    addresslocality: '',
    telephone: '',
    civil: '',
    firstname: '',
    lastname: '',
  });
  const [notification, setNotification] = useState(null);
  const [errors, setErrors] = useState({}); // Added state for errors
  useEffect(() => {
    // handlegetProUserProfile();
      if(currentUser && currentUser.UserProDetails && currentUser.UserProDetails.society){  
        setSocietyData({
            company_name: currentUser?.UserProDetails?.society?.company_name,
            address:currentUser?.UserProDetails?.society?.address,
            comp_address: currentUser?.UserProDetails?.society?.comp_address,
            postalcode: currentUser?.UserProDetails?.society?.postal_code,
            addresslocality: currentUser?.UserProDetails?.society?.ville,
            telephone:currentUser?.data?.telephone,
            user_id: currentUser?.data?.id,
            civil: currentUser?.data?.civil,
            firstname: currentUser?.data?.firstname,
            lastname: currentUser?.data?.lastname,
            
          })
      }
  }, [currentUser]);
  
  const handlePostalCodeChange = async (event, newValue, index) => {
    const postalCode = newValue?.postalcode || '';
    setSocietyData((prevData) => ({
      ...prevData,
      postalcode: postalCode,
      addresslocality: newValue?.cityArr && newValue.cityArr.length > 0 ? newValue.cityArr[0] : ''
    }));
  };
  const getAllCities = async (postalCode, index) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/getCitiesBycheckzipcode/${postalCode}`);
      
      if (response.status === 200 && response.data.sucess) {
        const cityData = response.data.data.cityArr;
        setSocietyData((prevData) => ({
          ...prevData,
          addresslocality: cityData.length > 0 ? cityData[0] : '',
        }));
        setCitiesData(cityData);
        
        
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.error('Error during API call:', error);
      if (error.response && error.response.status === 400 && error.response.data.success === false) {
        setErrors(error.response.data.msg || 'API call failed');
      }
    }
  };

  /* const fetchData = async () => {
    try {
      const user_id = localStorage.getItem("id");
      // const response = await getResponse('api/prouser/get-userbyid/', storedId);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/prouser/get-userbyid/${user_id}`);

      if (response) {
        const data = response.data.data[0];
        setSocietyData({
          company_name: data.company_name,
          address: data.address,
          comp_address: data.comp_address,
          postalcode: data.postal_code,
          addresslocality: data.ville,
          telephone: data.telephone,
        });
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      if(error.response.status === 401){
        LogoutApp();
      }
    }
  }; */

  useEffect(() => {
    // fetchData();
  }, []); // Empty dependency array ensures the effect runs only once after the initial render

  const validateForm = () => {
    const newErrors = {};
    if (!societyData.company_name.trim()) {
      newErrors.company_name = 'Le nom de la société est requis.';
    }
    if (!societyData.address.trim()) {
      newErrors.address = 'L\'adresse est requise.';
    }
    // Add similar checks for other fields

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleRegistration = async () => {
    try {
      // Validate the form before making the API call
      if (!validateForm()) {
        return;
      }

      const response = await updateResponse('api/prouser/update-company-details', societyData);
      
      if (response.data.success) {       
        setNotification({ message: 'données mises à jour avec succès', type: 'success' });
        let UserProDetails = response.data.data;
        dispatch({ type: userConstants.USER_PRO_DETAILS, UserProDetails });
        if(localStorage.getItem('user_pro')!=null){
            let getLocalUser = JSON.parse(localStorage.getItem('user_pro'));
            getLocalUser.UserProDetails = UserProDetails;
            localStorage.setItem('user_pro', JSON.stringify(getLocalUser));
        }
        setTimeout(() => {
          // window.location.reload();
          setOpen(false); // Close the dialog
        }, 2000);        
        setSocietyData({ company_name: '', address: '', comp_address: '', postalcode: '', addresslocality: '', telephone: '', }); // Clear form fields
      } else {
        setNotification({ message: 'Échec de la mise à jour des données.', type: 'error' });
        
      }
    } catch (error) {
      setNotification({ message: `Une erreur s'est produite lors de la mise à jour des données de présentation.`, type: 'error' });
      console.error('Error during API call:', error);
      if (error.response && error.response.status === 400 && error.response.data.success === false) {
        console.error(error.response.data.msg || 'API call failed');
      }
      if(error.response &&  error.response.status === 401){
       // LogoutApp();
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    setNotification(null);
  };

  const handleClose = () => {
    setOpen(false);
    setNotification(null);
    setErrors({}); // Clear errors when closing the dialog
  };

  const handleToastClose = () => {
    setNotification(null);
  };

  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={handleClickOpen}
      >
        <circle cx="18" cy="18" r="17.5" stroke="#27295B" />
        <path
          d="M19.1973 12.3902L23.6096 16.8028L14.0284 26.3844L10.0945 26.8187C9.56783 26.8769 9.12288 26.4316 9.18147 25.9049L9.61919 21.9681L19.1973 12.3902ZM26.3386 11.7333L24.2669 9.66146C23.6207 9.01519 22.5725 9.01519 21.9263 9.66146L19.9773 11.6106L24.3896 16.0231L26.3386 14.074C26.9849 13.4274 26.9849 12.3796 26.3386 11.7333Z"
          fill="#27295B"
        />
      </svg>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        {notification && <ToastNotification message={notification.message} type={notification.type} onClose={handleToastClose} />}
        <DialogTitle sx={{ m: 0, p: 2, color: "#27295B", fontWeight: "bold" }} id="customized-dialog-title">Société</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 4,
          }}
        >
          <IoMdCloseCircle size={40} />
        </IconButton>
        <DialogContent dividers>
          <div style={{ padding: '20px' }}>
            <div style={{ marginTop: '20px' }} className="grid grid-cols-12 items-center mt-3 sm:mt-6 p-4 w-full">
              <label className="col-span-12 text-base font-medium text-gray mb-4">Civilité</label>
              <div className="col-span-12 w-full">
              <Select
              style={{ width: '100%', background: '#F4F4F4' }}
                labelId="Civilite"
                label="Civilité"
                name="civil"
                value={societyData.civil}
                onChange={(e) => setSocietyData({ ...societyData, civil: e.target.value })}
              >
                <MenuItem value={"M"}>M</MenuItem>
                <MenuItem value={"MME"}>MME</MenuItem>
              </Select>
              {errors.civil && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.civil}</p>}
              </div>
            </div>
            <div style={{ marginTop: '20px' }} className="grid grid-cols-12 items-center mt-3 mb-1 sm:mt-6 p-4 w-full">
              <label className="col-span-12 text-base font-medium text-gray mb-4">Nom</label>
              <div className="col-span-12 w-full">
                <TextField
                  hiddenLabel
                  style={{ width: '100%', background: '#F4F4F4' }}
                  id="outlined-size-small"
                  size="small"
                  name="firstname"
                  value={societyData.firstname}
                  onChange={(e) => setSocietyData({ ...societyData, firstname: e.target.value })}
                  className={`outline-secondary focus:outline-secondary ${errors.firstname ? 'border-red-500' : ''}`}
                />
                {errors.firstname && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.firstname}</p>}
              </div>
            </div>
            <div style={{ marginTop: '20px' }} className="grid grid-cols-12 items-center mt-3 sm:mt-6 p-4 w-full">
              <label className="col-span-12 text-base font-medium text-gray mb-4">Prénom</label>
              <div className="col-span-12 w-full">
                <TextField
                  hiddenLabel
                  style={{ width: '100%', background: '#F4F4F4' }}
                  id="outlined-size-small"
                  size="small"
                  name="lastname"
                  value={societyData.lastname}
                  onChange={(e) => setSocietyData({ ...societyData, lastname: e.target.value })}
                  className={`outline-secondary focus:outline-secondary ${errors.lastname ? 'border-red-500' : ''}`}
                />
                {errors.lastname && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.lastname}</p>}
              </div>
            </div>
            <div style={{ marginTop: '20px' }} className="grid grid-cols-12 items-center mt-3 sm:mt-6 p-4 w-full">
              <label className="col-span-12 text-base font-medium text-gray mb-4">Telephone</label>
              <div className="col-span-12 w-full">
                <TextField
                  hiddenLabel
                  style={{ width: '100%', background: '#F4F4F4' }}
                  id="outlined-size-small"
                  size="small"
                  name="telephone"
                  value={societyData.telephone}
                  onChange={(e) => setSocietyData({ ...societyData, telephone: e.target.value })}
                  className={`outline-secondary focus:outline-secondary ${errors.telephone ? 'border-red-500' : ''}`}
                />
                {errors.telephone && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.telephone}</p>}
              </div>
            </div>
            <div style={{ marginTop: '20px' }} className="grid grid-cols-12 items-center mt-3 sm:mt-6 p-4 w-full">
              <label className="col-span-12 text-base font-medium text-gray mb-4">Nom de la société</label>
              <div className="col-span-12 w-full">
                <TextField
                  hiddenLabel
                  style={{ width: '100%', background: '#F4F4F4' }}
                  id="outlined-size-small"
                  size="small"
                  name="company_name"
                  value={societyData.company_name}
                  onChange={(e) => setSocietyData({ ...societyData, company_name: e.target.value })}
                  className={`outline-secondary focus:outline-secondary ${errors.company_name ? 'border-red-500' : ''}`}
                />
                {errors.company_name && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.company_name}</p>}
              </div>
            </div>
            <div style={{ marginTop: '20px' }} className="grid grid-cols-12 items-center mt-3 sm:mt-6 w-full">
              <label className="col-span-12 text-base font-medium text-gray mb-1">Adresse</label>
              <div className="col-span-12 w-full">
                <TextField
                  hiddenLabel
                  style={{ width: '100%', background: '#F4F4F4' }}
                  id="outlined-size-small"
                  size="small"
                  name="address"
                  value={societyData.address}
                  onChange={(e) => setSocietyData({ ...societyData, address: e.target.value })}
                  className={`w-full outline-secondary focus:outline-secondary ${errors.address ? 'border-red-500' : ''}`}
                />
                {errors.address && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.address}</p>}
              </div>
            </div>
            <div style={{ marginTop: '20px' }} className="grid grid-cols-12 items-center mt-3 sm:mt-6">
              <label className="col-span-12 text-base font-medium text-gray mb-1">Complément d’adresse</label>
              <div className="col-span-12">
                <TextField
                  hiddenLabel
                  style={{ width: '100%', background: '#F4F4F4' }}
                  id="outlined-size-small"
                  size="small"
                  name="comp_address"
                  value={societyData.comp_address}
                  onChange={(e) => setSocietyData({ ...societyData, comp_address: e.target.value })}
                  className="outline-secondary focus:outline-secondary"
                />
                {errors.comp_address && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.comp_address}</p>}
              </div>
            </div>
            <div style={{ marginTop: '20px' }} className="grid grid-cols-12 items-center mt-3 sm:mt-6">
              <label className="col-span-12 text-base font-medium text-gray mb-1">Code postal</label>
              <div className="col-span-12">
                <TextField
                  hiddenLabel
                  style={{ width: '100%', background: '#F4F4F4' }}
                  id="outlined-size-small"
                  size="small"
                  name="postalcode"
                  onChange={(e) => {
                    // handleChange(e);
                    getAllCities(e.target.value);
                    setSocietyData((prevData) => ({
                      ...prevData,
                      postalcode: e.target.value || '',
                    }));
                  }}
                  value={societyData.postalcode}
                  className="outline-secondary focus:outline-secondary"
                />
                {errors.postalcode && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.postalcode}</p>}
              </div>
            </div>
            <div style={{ marginTop: '20px' }} className="grid grid-cols-12 items-center mt-3 sm:mt-6">
              <label className="col-span-12 text-base font-medium text-gray mb-1">Ville</label>
              <div className='col-span-12'>
                <Autocomplete
                  options={citiesData}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    handlePostalCodeChange(event, newValue, 0);
                    setSocietyData((prevData) => ({
                      ...prevData,
                      addresslocality: newValue || '',
                    }));
                  }}
                  name="addresslocality"
                  value={societyData.addresslocality}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      hiddenLabel
                      style={{ width: '100%', background: '#F4F4F4' }}
                      id="outlined-size-small"
                      size="small"
                      name="addresslocality"
                      value={societyData.addresslocality}
                      onChange={(e) => setSocietyData({ ...societyData, addresslocality: e.target.value })}
                      className="outline-secondary focus:outline-secondary"
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <button
            autoFocus
            onClick={handleRegistration}
            type="submit"
            className="font-semibold p-4 bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white mt-4 sm:mt-4 3xl:mt-10 px-10 py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white"
            style={{
              backgroundColor: '#27295B',
              color: '#FFFFFF',
              padding: '10px',
              font: 'bold',
              fontWeight: 'bold',
              paddingLeft: '30px',
              paddingRight: '30px',
              borderRadius: '10px',
              float: 'right',
            }}
          >
            Enregistrer
          </button>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default CompanyModal;
